import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import UpdateIcon from "@material-ui/icons/Update";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import InputAdornment from "@material-ui/core/InputAdornment";
import OrganizationChooser from "components/StudentTable/OrganizationChooser.js";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import ListItem from "@material-ui/core/ListItem";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import Queries from "GraphQL/InstructorAccess.js";

import distiAuth from "disti-auth.js";
import util from "util.js";
import Snackbar from 'components/Snackbar/Snackbar.js';
import settings from '../../aws-exports.json';


const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  entryLine: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px"
  }
});

const showMarketDealer = true;

export default ({
  createNew,
  onApply,
  onValidateNewEmail,
  lookupStudentsForPersonId,
  onClose,
  onDelete,
  initialValue
}) => {
  const classes = useStyles();

  const { data: marketQueryData } = useQuery(Queries.MARKETS.ALL);

  const { allowedActions, assignedMarkets } = React.useContext(
    distiAuth.AllowedActionsContext
  );

  let allAllowedMarkets = [];
  let marketDataById = {};
  let marketDataByName = {}; // For Name=>GUID transition
  if (marketQueryData && marketQueryData.listMarkets.items) {
    const assignedMarketsSet = new Set(assignedMarkets);
    allAllowedMarkets = marketQueryData.listMarkets.items.filter(item => {
      return (
        allowedActions.noMarketRestrictions || assignedMarketsSet.has(item.id)
      );
    });

    allAllowedMarkets.forEach(item => {
      marketDataById[item.id] = item;
      marketDataByName[item.name] = item;
    });
  }

  const [email, setEmail] = React.useState(initialValue.id || "");
  const [fullName, setFullName] = React.useState(initialValue.fullName || "");
  const [market, setMarket] = React.useState(initialValue.market || "");
  const [dealer, setDealer] = React.useState(initialValue.dealer || "");
  const [personId, setPersonId] = React.useState(initialValue.personId || "");

  const handleCancel = () => {
    onClose();
  };
  const handleApply = () => {
    onApply({
      createNew,
      email,
      fullName,
      market,
      dealer,
      personId,
    });
    onClose();
  };
  const handleDelete = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete user: " + email + "?"
    );

    if (isConfirmed && onDelete && email) {
      onDelete(email);
      onClose();
    }
  };

  let whyBadEmail = "";
  const emailIsGood = candidateEmail => {
    if (!util.checkEmailFormatGood(candidateEmail)) {
      whyBadEmail = "Invalid email format.";
      return false;
    }
    const { valid, reason } = onValidateNewEmail
      ? onValidateNewEmail(candidateEmail)
      : {};
    if (!valid) {
      whyBadEmail = reason;
      return false;
    }
    return true;
  };

  const goodEmail = createNew ? emailIsGood(email) : true;
  const personIdIs = [settings.personIdFieldName];
  const otherStudentsForPersonId = lookupStudentsForPersonId(personId);
  const otherEmailAddresses = otherStudentsForPersonId.map(student=>student.id).filter(candidateEmail => email !== candidateEmail);
  let whyBadPersonId = "";
  const personIdIsGood = ()=>
  {
    if (!otherStudentsForPersonId || 0==otherStudentsForPersonId.length)
    {
      return true;
    }
    // Checking for an exact match only.
    // We do not want a lowercase check here because we say that any personIds in the system need to match by case to fit the customer's expectations
    if (otherStudentsForPersonId[0].personId === personId)
    {
      return true;
    }
    else
    {
      whyBadPersonId = "Expected capitalization: "+otherStudentsForPersonId[0].personId;
      return false;
    }
  }
  const goodPersonId = personIdIsGood();

  return (
    <Dialog
      open={true}
      onClose={handleCancel}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <CloseIcon
          style={{ position: "absolute", right: "25px", zIndex: "1" }}
          fontSize="large"
          onClick={handleCancel}
        />
      </DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>
            <Card className={classes.root}>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <EditIcon />
                </CardIcon>
                <h1 className={classes.cardIconTitle}>
                  {createNew ? "Create Student" : "Edit Student"}
                </h1>
              </CardHeader>

              <CardContent>
                <Typography color="textSecondary" variant="h5" component="h2">
                  <TextField
                    className={classes.entryLine}
                    required
                    disabled={!allowedActions.createStudents}
                    value={fullName}
                    id="fullName"
                    label="Full Name"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                      error: !fullName
                    }}
                    onChange={e => {
                      setFullName(e.target.value);
                    }}
                  />
                  <br />
                  <Tooltip title={whyBadEmail}>
                    <TextField
                      className={classes.entryLine}
                      required
                      disabled={!createNew}
                      value={email}
                      id="emailAddress"
                      label="Email Address"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                        error: !goodEmail
                      }}
                      onChange={e => {
                        setEmail((e.target.value || "").toLowerCase());
                      }}
                    />
                  </Tooltip>

                  <br />
                  {showMarketDealer ? (
                    <>
                      <OrganizationChooser
                        wide
                        required
                        className={classes.entryLine}
                        disabled={!allowedActions.assignStudentsToMarket}
                        availableOrganizations={allAllowedMarkets}
                        // Fix up the market from name to GUID
                        currentOrganization={
                          Object.prototype.hasOwnProperty.call(
                            marketDataById,
                            market
                          )
                            ? market
                            : Object.prototype.hasOwnProperty.call(
                              marketDataByName,
                              market
                            )
                              ? marketDataByName[market].id
                              : ""
                        }
                        onOrganizationChange={newValue => {
                          setMarket(newValue);
                        }}
                      />
                      <br />
                      <TextField
                        className={classes.entryLine}
                        value={dealer}
                        id="dealer"
                        label="Dealer"
                        disabled={!allowedActions.assignStudentsToDealer}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LocationCityIcon />
                            </InputAdornment>
                          )
                        }}
                        onChange={e => {
                          setDealer(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <br />
                  )}

                  <TextField
                    title={whyBadPersonId}
                    className={classes.entryLine}
                    required
                    disabled={!allowedActions.createStudents}
                    value={personId}
                    id="personID"
                    label={personIdIs}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                      error: !goodPersonId
                    }}
                    onChange={e => {
                      setPersonId(e.target.value);
                    }}
                  />
                  <br />
                  {
                    otherEmailAddresses.length ? <Typography variant="caption">
            
                      Same as:
                      {otherEmailAddresses.map(emailAddress => <Chip key={emailAddress} label={emailAddress} style={{marginLeft:"10px"}}/>)}
                      <br />
                    </Typography> : null
                  }
                  <br />
                  {createNew || !allowedActions.createStudents ? (
                    ""
                  ) : (
                    <DeleteIcon
                      style={{
                        marginTop: "15px",
                        marginBottom: "15px",
                        cursor: "pointer",
                        float: "right"
                      }}
                      onClick={handleDelete}
                    />
                  )}
                </Typography>
              </CardContent>

              <CardActions></CardActions>
            </Card>
          </GridItem>
        </GridContainer>
        <Button
          color="primary"
          style={{
            float: "left",
            marginLeft: "100px",
            marginTop: "20px",
            marginBottom: "20px"
          }}
          onClick={handleApply}
          disabled={!(goodEmail && fullName && market && goodPersonId)}
        >
          Confirm
        </Button>
        <Button
          style={{
            float: "right",
            marginRight: "100px",
            marginTop: "20px",
            marginBottom: "20px"
          }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};
