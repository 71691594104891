import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-6";

import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/client";

import Queries from "GraphQL/InstructorAccess.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import People from "@material-ui/icons/People";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import ListIcon from "@material-ui/icons/List";
import Select from "@material-ui/core/Select";
import SettingsIcon from "@material-ui/icons/Settings";
import SaveIcon from "@material-ui/icons/SaveAlt";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import BuildIcon from "@material-ui/icons/Build";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SchoolIcon from "@material-ui/icons/School";
import ContentEdit from "views/Pages/ContentEdit";
import MapIcon from "@material-ui/icons/Map";
import AddIcon from "@material-ui/icons/Add";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import SubscriptionHelpers from "GraphQL/SubscriptionHelpers.js";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";

import XLSX from "xlsx";
import GetAppIcon from "@material-ui/icons/GetApp";
import Fab from "@material-ui/core/Fab";

import distiAuth from "disti-auth.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const DefaultColumnFilter = ({ filter, onChange }) => {
  return (
    <input
      value={filter ? filter.value : ""}
      onChange={e => {
        //console.log("Got onChange: "+ JSON.stringify(e.target.value));
        onChange(e.target.value || []); // Set undefined to remove the filter entirely
      }}
      placeholder={`🔍`} //Search ${count} records...`}
    />
  );
};

const useStyles = makeStyles(styles);

export default function LessonSettingsPage() {
  const { allowedActions } = React.useContext(distiAuth.AllowedActionsContext);

  const { loading: contentLoading, data: contentData, refetch } = useQuery(
    Queries.CONTENT.ALL
  );

  const [updateContentPassCriteria] = useMutation(Queries.CONTENT.MODIFY_PASS);

  const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);
  const [menuTargetData, setMenuTargetData] = React.useState(null);

  const [contentEditData, setContentEditData] = React.useState(null);

  const defaultCriteria = 95;

  let reactTable = React.useRef(null);
  const [pageSize, setPageSize] = React.useState(null);

  let tableData = [];

  if (contentData) {
    console.log(contentData);
    contentData.listContent.items.forEach(item => {
      const availableSettings =
        JSON.parse(item.availableSettings || "[]") || [];
      const passCriteria = JSON.parse(item.lessonPassCriteria || "{}") || {};
      const baseRow = {
        id: item.id,
        contentName: item.name,
        availableSettings,
        passCriteria
      };
      const lessonData = availableSettings.find(entry => entry.id == "lesson");
      if (lessonData && lessonData.options) {
        lessonData.options.forEach(lessonEntry => {
          const newRow = {
            ...baseRow,
            lessonId: lessonEntry.id,
            lessonName: lessonEntry.name,
            criteria: passCriteria[lessonEntry.id]
          };
          newRow.actions = (
            <>
              <Button
                key={item.id}
                disabled={!allowedActions.setPassFailLevels}
                round
                size="sm"
                color="primary"
                onClick={event => {
                  setMenuAnchorElement(event.currentTarget);
                  setMenuTargetData(newRow);
                }}
              >
                <BuildIcon />
                <ArrowDropDownIcon />
              </Button>
            </>
          );
          tableData.push(newRow);
        });
      }
    });
  }
  const handleApply = value => {
    /*value: {
              id: item.id,
              contentName: item.name,
              availableSettings,
              passCriteria
              lessonId: lessonEntry.id,
              lessonName: lessonEntry.name,
              criteria: 
} */

    // Collect all the settings for a particular piece of content

    const entry = contentData.listContent.items.find(
      item => item.id == value.id
    );
    if (entry) {
      const existing = JSON.parse(entry.lessonPassCriteria || "{}");
      existing[value.lessonId] = value.criteria;

      const newPassCriteria = JSON.stringify(existing);

      updateContentPassCriteria({
        variables: { pk: value.id, lessonPassCriteria: newPassCriteria }
      });

      refetch();
    }
  };
  const handleCloseMenu = () => {
    setMenuAnchorElement(null);
    setMenuTargetData(null);
  };
  const classes = useStyles();
  return (
    <>
      <ContentEdit
        open={Boolean(contentEditData)}
        initialValue={contentEditData}
        onApply={handleApply}
        onClose={() => setContentEditData(null)}
      />
      <Menu
        id="action-menu"
        anchorEl={menuAnchorElement}
        open={Boolean(menuAnchorElement)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setContentEditData(menuTargetData);
            handleCloseMenu();
          }}
        >
          Edit Content
        </MenuItem>
      </Menu>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <PlaylistAddCheckIcon />
              </CardIcon>
              <h1 className={classes.cardIconTitle}>Lesson Passing Scores</h1>
            </CardHeader>
            <CardBody>
              <ReactTable
                ref={r => {
                  reactTable.current = r;
                }}
                data={tableData}
                filterable
                style={
                  {
                    //height: "calc( 75vh - 276px)"
                  }
                }
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id;
                  if (row[id] !== undefined) {
                    const haystack = String(row[id]).toLowerCase();
                    const needle = String(filter.value).toLowerCase();
                    return haystack.includes(needle);
                  }
                  return true;
                }}
                columns={[
                  {
                    Header: "Content Name",
                    accessor: "contentName",
                    Filter: DefaultColumnFilter,
                    width: 350
                  },
                  {
                    Header: "Lesson Name",
                    accessor: "lessonName",
                    Filter: DefaultColumnFilter,
                    width: 350
                  },
                  {
                    Header: "Passing Score",
                    accessor: "criteria",
                    width: 100,
                    filterable: false,
                    sortable: false,
                    Cell: props =>
                      props.original.criteria ? (
                        <>{props.original.criteria}%</>
                      ) : (
                        <i>{defaultCriteria}% (default)</i>
                      )
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    width: 150
                  }
                ]}
                defaultSorted={[
                  {
                    id: "contentName",
                    desc: false
                  }
                ]}
                onFilteredChange={() => {
                  setPageSize(
                    reactTable.current.getResolvedState().sortedData.length
                  );
                }}
                pageSize={pageSize != null ? pageSize : tableData.length}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
