import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import UpdateIcon from "@material-ui/icons/Update";

import AppStreamInstructorView from "components/LiveClassModal/AppStreamInstructorView.js";

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  liveAppStream: {
    float: "right",
    width: "80%",
    paddingTop: "45%",
    position: "relative",
    borderStyle: "inset",
    margin: "5px"
  },
  leftSide: {
    float: "left",
    position: "relative",
    margin: "5px",
    width: "calc(20% - 20px)"
  },
  biggerContentImage: {
    height: "20%",
    objectFit: "contain",
    float: "right",
    width: "unset",
    margin: "5px"
  },
  userThumbnail: {
    height: "75px",
    objectFit: "contain",
    width: "unset",
    marginBottom: "5px"
  }
});
const ThinLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 10
  },
  bar: {
    borderRadius: 10
  },
  colorPrimary: {
    backgroundColor: "#a4a8ab"
  },
  barColorPrimary: {
    backgroundColor: "#40bf40"
  }
})(LinearProgress);

export default function LiveStudentDetail({
  accessObj,
  show,
  item,
  classDescription
}) {
  const classes = useStyles();

  const handleCancel = () => {
    accessObj.closeDetail();
  };
  let textEntry = (tooltip, text) => {
    return (
      <Tooltip title={tooltip}>
        <Typography className={classes.pos} color="textSecondary">
          {text}
        </Typography>
      </Tooltip>
    );
  };

  return show ? (
    <Dialog
      open={show}
      onClose={handleCancel}
      fullWidth={true}
      maxWidth={false}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <CloseIcon
          style={{ position: "absolute", right: "25px", zIndex: "1" }}
          fontSize="large"
          onClick={handleCancel}
        />
      </DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>
            <Card className={classes.root}>
              <CardContent>
                <Tooltip title={item.email}>
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{ float: "left" }}
                  >
                    {item.fullName}
                  </Typography>
                </Tooltip>
                <Tooltip title="Current Class">
                  <Typography
                    variant="h5"
                    component="h3"
                    style={{ float: "right" }}
                  >
                    {classDescription}
                  </Typography>
                </Tooltip>

                <Tooltip
                  title={
                    "Lesson Progress: " + Math.round(item.progressPercent) + "%"
                  }
                >
                  <ThinLinearProgress
                    variant="determinate"
                    value={item.progressPercent}
                    style={{ width: "100%", display: "inline-block" }}
                  />
                </Tooltip>
                <div className={classes.leftSide}>
                  {textEntry("Training Mode", item.trainingMode)}
                  {textEntry("Content", item.currentContent)}
                  {textEntry("Current Lesson Name", item.currentLesson)}
                  {textEntry(
                    "Elapsed Time in Current Lesson",
                    item.elapsedTime
                  )}
                  <hr />
                  <CardMedia
                    component="img"
                    className={classes.userThumbnail}
                    image={item.userThumbnail}
                  />
                  {textEntry("Connected Time", item.connectedTime)}
                  {textEntry("Connected From Country", item.countryName)}
                  {textEntry("Market", item.market)}
                  {textEntry("Dealer", item.dealer)}
                </div>
                <div className={classes.liveAppStream}>
                  <AppStreamInstructorView streamingURL={item.launchURL} />
                </div>
              </CardContent>
            </Card>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  ) : (
    ""
  );
}
