import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-6";
import Queries from "GraphQL/InstructorAccess.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import MapIcon from "@material-ui/icons/Map";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import BuildIcon from "@material-ui/icons/Build";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";
import ContentZipActivelyUploading from "./ContentZipActivelyUploading";
import ContentImage from "./ContentImage";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { isNullLiteral } from "@babel/types";
import distiAuth from "disti-auth";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  entryLine: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px"
  }
};

const useStyles = makeStyles(styles);

const useZipFileValidator = ({ filename }) => {
  const [loading, setLoading] = React.useState(true);
  const [valid, setValid] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [metadata, setMetadata] = React.useState(null);
  const [imageBlobFromZip, setImageBlobFromZip] = React.useState(null);

  React.useEffect(() => {
    const doIt = async () => {
      setLoading(true);
      setValid(false);
      setErrors(null);
      setMetadata(null);

      if (!filename) {
        setValid(false);
        setErrors(was => [...(was || []), "Filename not provided"]);
      }
      try {
        const zip = window.zip; // From @zip.js/zip.js (https://gildas-lormeau.github.io/zip.js/)

        const reader = new zip.ZipReader(new zip.BlobReader(filename));

        // get all entries from the zip
        const entries = await reader.getEntries();
        const getJsonFromFile = async filename => {
          // get first entry content as text by using a TextWriter
          console.log("Entries: ", entries);
          const entry = entries.find(entry => entry.filename == filename);
          console.log({ entry });
          const text = await entry.getData(new zip.TextWriter(), {
            /*options*/
          });
          return JSON.parse(text);
        };
        const getImageDataFromFile = async filename => {
          try {
            // get first entry content as text by using a TextWriter
            const entry = entries.find(entry => entry.filename == filename);
            if (entry) {
              const imageBlob = await entry.getData(
                new zip.BlobWriter(["image/png"]),
                {
                  /*options*/
                }
              );
              return imageBlob;
            } else {
              return null;
            }
          } catch (e) {
            console.log("Problem in getImageDataFromFile:", e);
            return null;
          }
        };
        const doesFileExists = async filename => {
          return entries.some(entry => entry.filename == filename);
        };

        if (!(await doesFileExists("content_description.json"))) {
          setErrors([
            "Zip file does not contain content_description.json.  Aborting."
          ]);
          setValid(false);
        } else {
          const contentDescription = await getJsonFromFile(
            "content_description.json"
          );
          console.log(contentDescription);
          const imageBlob = await getImageDataFromFile("image.png");
          if (imageBlob) setImageBlobFromZip(imageBlob);

          const rval = {
            valid: false,
            filename: filename,
            error: { issues: [] },
            details: contentDescription
          };
          const tempErrors = [];
          if (!contentDescription.StartFileName) {
            tempErrors.push(
              "StartFileName in content_description.json needs a value"
            );
          }
          if (!contentDescription.StopFileName) {
            tempErrors.push(
              "StopFileName in content_description.json needs a value"
            );
          }
          if (!contentDescription.ExecutableNameForRunningCheck) {
            tempErrors.push(
              "ExecutableNameForRunningCheck in content_description.json needs a value"
            );
          }
          if (!contentDescription.SupportsLessonModification) {
            rval.details.SupportsLessonModification = false;
          }
          if (!(await doesFileExists(contentDescription.StartFileName))) {
            tempErrors.push(
              contentDescription.StartFileName + " is not in the zip file"
            );
          }
          if (!(await doesFileExists(contentDescription.StopFileName))) {
            tempErrors.push(
              contentDescription.StopFileName + " is not in the zip file"
            );
          }
          setErrors(tempErrors);
          let availableSettings = "";
          try {
            if (rval.details.AvailableSettings) {
              availableSettings = JSON.stringify(
                rval.details.AvailableSettings
              );
            }
          } catch (e) {}
          setMetadata({
            name: rval.details.Name,
            description: rval.details.Description,
            highlights: rval.details.Highlights,
            startFileName: rval.details.StartFileName,
            stopFileName: rval.details.StopFileName,
            supportsLessonModification: rval.details.SupportsLessonModification,
            executableNameForRunningCheck:
              rval.details.ExecutableNameForRunningCheck,
            availableSettings,
            tags: [] // TODO
          });
          // close the ZipReader
          await reader.close();

          setValid(tempErrors.length == 0);
        }
        setLoading(false);
      } catch (e) {
        console.log("Problem processing .zip file: ", e);
        setErrors(was => [
          ...(was || []),
          "Exception while processing.  See log."
        ]);
        setValid(false);
        setLoading(false);
      }
    };
    doIt();
    return undefined; // Nothing to clean up
  }, [filename]);
  return { loading, valid, errors, metadata, imageBlobFromZip };
};
const errorCellStyle = { outline: "red", outlineStyle: "solid" };

export default function ContentZipUpload({
  filename,
  currentEntryData,
  updateEntryData,
  onClose
}) {
  const [doTheUpload, setDoTheUpload] = React.useState(null);

  const {
    loading,
    valid,
    errors,
    metadata,
    imageBlobFromZip
  } = useZipFileValidator({ filename });

  const [
    importKeepOriginalPropertiesSet,
    setImportKeepOriginalPropertiesSet
  ] = React.useState(new Set());
  const changeImportKeepOriginalPropertiesSet = (property, value) => {
    setImportKeepOriginalPropertiesSet(was => {
      const newSet = new Set(was);
      if (value) {
        newSet.add(property);
      } else {
        newSet.delete(property);
      }
      return newSet;
    });
  };

  const pendingImportData =
    !loading && valid && metadata && currentEntryData
      ? {
          ...currentEntryData,
          ...Object.keys(metadata)
            .filter(key => !importKeepOriginalPropertiesSet.has(key))
            .reduce((obj, key) => {
              obj[key] = metadata[key];
              return obj;
            }, {})
        }
      : {};

  const pendingImageUploadBlob = !importKeepOriginalPropertiesSet.has(
    "imageData"
  )
    ? imageBlobFromZip
    : null;

  return true ? (
    <>
      {doTheUpload ? (
        <ContentZipActivelyUploading
          filename={doTheUpload.filename}
          metadata={doTheUpload.metadata}
          onClose={() => {
            setDoTheUpload(null);
            doTheUpload.onClose();
          }}
          onSuccess={async () => {
            updateEntryData({
              value: {
                ...doTheUpload.metadata,
                zipLastUpdated: Math.floor(Date.now() / 1000),
                whoLastUpdatedZip: distiAuth.getUsername()
              }
            });
            if (doTheUpload.onSuccess) {
              return await doTheUpload.onSuccess();
            }
          }}
        />
      ) : null}
      <Dialog
        open={
          true //Boolean(fileUploadStatus && metadata && currentEntryData)
        }
        onClose={onClose}
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Confirm metadata
          <CloseIcon
            style={{ position: "absolute", right: "25px", zIndex: "1" }}
            fontSize="large"
            onClick={onClose}
          />
          <br />
          {loading
            ? "Loading..."
            : "importing " + filename.name
            ? filename.name
            : ""}
        </DialogTitle>
        {loading ? (
          <DialogContent>Loading...</DialogContent>
        ) : (
          <DialogContent>
            <>
              {
                // Show a now, vs after UI
                // it should allow for keeping or replacing certain things
                // It should only allow keeping metadata that isn't changable.
              }
              {!valid ? (
                errors && errors.length ? (
                  <ul>
                    {errors.map(item => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                ) : null
              ) : (
                <GridContainer>
                  <GridItem xs={12}>
                    <Table aria-label="Import Confirmation">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: "5%" }}>
                            Property
                          </TableCell>
                          <TableCell style={{ width: "30%" }} align="center">
                            Current
                          </TableCell>
                          <TableCell style={{ width: "5%" }} align="center">
                            Keep Current
                          </TableCell>
                          <TableCell style={{ width: "30%" }} align="center">
                            From File
                          </TableCell>
                          <TableCell style={{ width: "30%" }} align="center">
                            New
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center">Name</TableCell>
                          <TableCell align="center">
                            {currentEntryData.name}
                          </TableCell>
                          <TableCell align="center">
                            <Switch
                              checked={importKeepOriginalPropertiesSet.has(
                                "name"
                              )}
                              onChange={e => {
                                changeImportKeepOriginalPropertiesSet(
                                  "name",
                                  e.currentTarget.checked
                                );
                              }}
                              color="primary"
                              name="name"
                              inputProps={{ "aria-label": "name" }}
                            />
                          </TableCell>
                          <TableCell align="center">{metadata.name}</TableCell>
                          <TableCell
                            align="center"
                            style={pendingImportData.name ? {} : errorCellStyle}
                          >
                            {pendingImportData.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">Description</TableCell>
                          <TableCell align="center">
                            {currentEntryData.description}
                          </TableCell>
                          <TableCell align="center">
                            <Switch
                              checked={importKeepOriginalPropertiesSet.has(
                                "description"
                              )}
                              onChange={e => {
                                changeImportKeepOriginalPropertiesSet(
                                  "description",
                                  e.currentTarget.checked
                                );
                              }}
                              color="primary"
                              name="description"
                              inputProps={{ "aria-label": "description" }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {metadata.description}
                          </TableCell>
                          <TableCell align="center">
                            {pendingImportData.description}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">Highlights</TableCell>
                          <TableCell align="center">
                            {currentEntryData.highlights || ""}
                          </TableCell>
                          <TableCell align="center">
                            <Switch
                              checked={importKeepOriginalPropertiesSet.has(
                                "highlights"
                              )}
                              onChange={e => {
                                changeImportKeepOriginalPropertiesSet(
                                  "highlights",
                                  e.currentTarget.checked
                                );
                              }}
                              color="primary"
                              name="highlights"
                              inputProps={{ "aria-label": "highlights" }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {metadata.highlights || ""}
                          </TableCell>
                          <TableCell align="center">
                            {pendingImportData.highlights}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">Start File</TableCell>
                          <TableCell align="center">
                            {currentEntryData.startFileName}
                          </TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            {metadata.startFileName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={
                              pendingImportData.startFileName
                                ? {}
                                : errorCellStyle
                            }
                          >
                            {pendingImportData.startFileName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">Stop File</TableCell>
                          <TableCell align="center">
                            {currentEntryData.stopFileName}
                          </TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            {metadata.stopFileName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={
                              pendingImportData.stopFileName
                                ? {}
                                : errorCellStyle
                            }
                          >
                            {pendingImportData.stopFileName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">Executable</TableCell>
                          <TableCell align="center">
                            {currentEntryData.executableNameForRunningCheck}
                          </TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            {metadata.executableNameForRunningCheck}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={
                              pendingImportData.executableNameForRunningCheck &&
                              pendingImportData.executableNameForRunningCheck.endsWith(
                                ".exe"
                              )
                                ? {}
                                : errorCellStyle
                            }
                          >
                            {pendingImportData.executableNameForRunningCheck}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">
                            Available Settings
                          </TableCell>
                          <TableCell align="center">
                            {currentEntryData.availableSettings ? "Yes" : "No"}
                          </TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            {metadata.availableSettings ? "Yes" : "No"}
                          </TableCell>
                          <TableCell align="center">
                            {pendingImportData.availableSettings ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">
                            Supports Lesson Modification
                          </TableCell>
                          <TableCell align="center">
                            { currentEntryData.supportsLessonModification ? "Yes" : "No" }
                          </TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">
                            { metadata.supportsLessonModification ? "Yes" : "No" }
                          </TableCell>
                          <TableCell align="center">
                            { pendingImportData.supportsLessonModification ? "Yes" : "No" }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">Image</TableCell>
                          <TableCell align="center">
                            <ContentImage id={currentEntryData.id} />
                          </TableCell>
                          <TableCell align="center">
                            <Switch
                              checked={importKeepOriginalPropertiesSet.has(
                                "imageData"
                              )}
                              onChange={e => {
                                changeImportKeepOriginalPropertiesSet(
                                  "imageData",
                                  e.currentTarget.checked
                                );
                              }}
                              color="primary"
                              name="imageData"
                              inputProps={{ "aria-label": "imageData" }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {pendingImageUploadBlob ? (
                              <img
                                src={URL.createObjectURL(imageBlobFromZip)}
                                style={{
                                  maxWidth: "75px",
                                  maxHeight: "50px",
                                  objectFit: "contain"
                                }}
                              />
                            ) : null}
                          </TableCell>
                          <TableCell align="center">
                            {pendingImageUploadBlob ? (
                              <img
                                src={URL.createObjectURL(
                                  pendingImageUploadBlob
                                )}
                                style={{
                                  maxWidth: "75px",
                                  maxHeight: "50px",
                                  objectFit: "contain"
                                }}
                              />
                            ) : (
                              <ContentImage id={currentEntryData.id /*TODO*/} />
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </GridItem>
                </GridContainer>
              )}
              <Button
                color="primary"
                style={{
                  float: "right",
                  marginLeft: "100px",
                  marginTop: "20px",
                  marginBottom: "20px"
                }}
                onClick={() => {
                  setDoTheUpload({
                    filename,
                    metadata: pendingImportData,
                    onClose: () => {
                      setDoTheUpload(null);
                      onClose();
                    },
                    onSuccess: async () => {
                      //Push the image data if necessary.
                      if (pendingImageUploadBlob) {
                        return await distiAuth.uploadContentImageBlob({
                          contentId: pendingImportData.id,
                          blob: pendingImageUploadBlob
                        });
                      }
                      return true;
                    }
                  });
                }}
                disabled={
                  !valid ||
                  !pendingImportData.name ||
                  !pendingImportData.startFileName ||
                  !pendingImportData.stopFileName ||
                  !pendingImportData.executableNameForRunningCheck ||
                  !pendingImportData.executableNameForRunningCheck.endsWith(
                    ".exe"
                  )
                }
              >
                Import
              </Button>
              <Button
                style={{
                  float: "left",
                  marginRight: "100px",
                  marginTop: "20px",
                  marginBottom: "20px"
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </>
          </DialogContent>
        )}
      </Dialog>
    </>
  ) : null;
}
