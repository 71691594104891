import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";

import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "@cvanduyne/aws-appsync-subscription-link";
import { onError } from "@apollo/client/link/error";

import {
  ApolloLink,
  ApolloProvider,
  ApolloClient,
  from,
  split,
  InMemoryCache
} from "@apollo/client";
import { createHttpLink } from "apollo-link-http";

import settings from "aws-exports.json";

import querystring from "querystring";

import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import Tooltip from "@material-ui/core/Tooltip";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import SchoolhouseHorizontalLogo from "assets/img/Schoolhouse-Logo-Horizontal-Md.png";
import SchoolhouseStackedLogo from "assets/img/Schoolhouse-Logo-White-Sm.png";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";

import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks";

import HelpIcon from "@material-ui/icons/Help";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import LoginPage from "views/Pages/LoginPage.js";
import NewPasswordPage from "views/Pages/NewPasswordPage.js";
import Hidden from "@material-ui/core/Hidden";
import ExpandIcon from "@material-ui/icons/SettingsEthernet";
import ExitIcon from "@material-ui/icons/ExitToApp";
import originalRoutes from "instructor-routes.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import SnackbarMui from "@material-ui/core/Snackbar";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import AWS from "aws-sdk";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/CustomButtons/Button.js";
import {
  PrivacyStatement,
  currentStatementId
} from "views/PrivacyStatement.js";
import distiAuth from "disti-auth.js";
import DistiAuth from "disti-auth.js";
import util from "util.js";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import Queries from "GraphQL/InstructorAccess.js";
import GlobalCallControl from "components/FacilitatorDashboard/GlobalCallControl.js";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MuiAlert from "@material-ui/lab/Alert";
//import {HelpOverlay} from 'views/HelpOverlay.js';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]:`, networkError);
});

const currentAgreementId = currentStatementId;

var ps;

const supportPassFailSupport = true; //window.location.search.includes("passFailSupport=103")

distiAuth.init({ userType: "INSTRUCTOR" });

let client = null;
const createClient = () => {
  if (!client) {
    const auth = {
      type: "AWS_IAM",
      credentials: () => distiAuth.getCredentialsUpdatedAsNeeded() //() => Amplify.Auth.currentCredentials(),
    };
    const region = settings.CoreRegion;
    const url = settings.GraphqlEndpoint;

    const httpLink = createHttpLink({ uri: url });

    const argsForWebSocket = { url: url, region: "us-east-1", auth: auth };

    const splited = split(
      op => {
        const { operation } = op.query.definitions[0];

        if (operation === "subscription") {
          return false;
        }

        return true;
      },
      httpLink,
      createSubscriptionHandshakeLink(
        {
          auth,
          region,
          url
        },
        httpLink
      )
    );
    const args = { url, region, auth, disableOffline: true };
    const link = ApolloLink.from([
      errorLink,
      createAuthLink(args),
      createSubscriptionHandshakeLink(args)
    ]);

    const preferNonNull = (existing, incoming) =>
      incoming === null ? existing : incoming;

    const cache = new InMemoryCache({
      typePolicies: {
        UserStatus: {
          keyFields: ["userId"],
          fields: {
            contentThumbnail: { merge: preferNonNull },
            countryCode: { merge: preferNonNull },
            currentClass: { merge: preferNonNull },
            currentContent: { merge: preferNonNull },
            currentLesson: { merge: preferNonNull },
            currentRegion: { merge: preferNonNull },
            deleteStatusAt: { merge: preferNonNull },
            elapsedTime: { merge: preferNonNull },
            launchURL: { merge: preferNonNull },
            loginTime: { merge: preferNonNull },
            percentComplete: { merge: preferNonNull },
            trainingMode: { merge: preferNonNull },
            updateTime: { merge: preferNonNull },
            //userId: { merge: preferNonNull },
            userThumbnail: { merge: preferNonNull }
          }
        }
      }
    });
    client = new ApolloClient({
      link,
      cache,
      disableOffline: true
    });
  } else {
    console.log("client already created.");
  }
};
const useStyles = makeStyles(styles);
let username = "";

const filterRoutes = (originalRoutes, allowedActions) => {
  const userData = distiAuth.getUserData();

  //console.log("filterRoutes userData: "+JSON.stringify(userData));
  const showUtilities =
    allowedActions.viewStudentUsage || allowedActions.getPricing;
  //console.log("filterRoutes userData: "+JSON.stringify(userData) +" showUtilities: "+showUtilities);
  const showTrainerManagement =
    util.previewPhase3_2 &&
    (allowedActions.createBaseUser || allowedActions.addFacilitatorRole);
  const showMarketManagement =
    util.previewPhase3_2 && allowedActions.createMarket;
  const showFacilitatorDashboard =
    util.previewPhase3_2 && allowedActions.viewLiveStudentSessions;
  const showLiveStudentSessions = allowedActions.viewLiveStudentSessions;
  const showLessonSettings =
    supportPassFailSupport &&
    (allowedActions.assignContentToMarket || allowedActions.setPassFailLevels);
  const showContentPage = allowedActions.uploadContent;

  return originalRoutes.filter(item => {
    if (item.path === "/utilities" && !showUtilities) return false;
    if (item.path === "/trainer-management" && !showTrainerManagement)
      return false;
    if (item.path === "/market-management" && !showMarketManagement)
      return false;
    if (item.path === "/lesson-settings" && !showLessonSettings) return false;
    if (item.path === "/content" && !showContentPage) return false;
    //if (item.path === "/active-classes" && !showLiveStudentSessions) return false;
    if (item.path === "/facilitator-dashboard" && !showFacilitatorDashboard)
      return false;

    return true;
  });
};
const TrainerDetails = () => {
  const userData = distiAuth.getUserData();
  const username = distiAuth.getHashedPW().username;

  const { loading: loadingMarketData, data: marketQueryData } = useQuery(
    Queries.MARKETS.ALL
  );
  const { allowedActions } = React.useContext(distiAuth.AllowedActionsContext);

  const roleNamesByRoleId = {};
  for (const roleId in settings.roles) {
    roleNamesByRoleId[roleId] = settings.roles[roleId].name;
  }

  let marketNamesById = {};
  if (marketQueryData && marketQueryData.listMarkets.items) {
    marketQueryData.listMarkets.items.forEach(item => {
      marketNamesById[item.id] = item.name;
    });
  }
  return (
    <>
      <Typography>{username || userData.fullName || "---"}</Typography>
      <span>
        <Divider />
        <Typography>Roles</Typography>
        {(userData.roles || []).map(item => {
          return (
            <Chip
              style={{
                margin: "1px"
              }}
              label={roleNamesByRoleId[item]}
              key={item}
            />
          );
        })}
        <Divider />
        {allowedActions.noMarketRestrictions ? (
          <Typography>All Markets</Typography>
        ) : (
          <>
            <Typography>Markets</Typography>
            {(userData.markets || []).map(item => {
              return (
                <Chip
                  style={{
                    margin: "1px"
                  }}
                  label={marketNamesById[item]}
                  key={item}
                />
              );
            })}
          </>
        )}
      </span>
    </>
  );
};

export default function Dashboard(props) {
  const { ...rest } = props;

  console.log("props.location.search: " + props.location.search);

  var q = {};
  try {
    q = querystring.parse(props.location.search.substring(1)); // Skip the "?"
  } catch (e) {}

  console.log("============================q: " + JSON.stringify(q));
  const resetNonce = q.nonce;

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(true);
  const [image, setImage] = React.useState(""); //require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = React.useState("white");
  const [bgColor, setBgColor] = React.useState("blue"); //black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  //  const [logo, setLogo] = React.useState(require("../DiSTI-Logo white.png"));//assets/img/logo-white.svg"));
  const [logo, setLogo] = React.useState(SchoolhouseStackedLogo); //assets/img/logo-white.svg"));
  const [loggedIn, setLoggedIn] = React.useState(false);
  //const [userData, setUserData] = React.useState({});
  const [newPasswordMode, setNewPasswordMode] = React.useState(
    !!q.nonce && !!q.email
  );
  const [loginFailMessage, setLoginFailMessage] = React.useState("");

  const [acceptedAgreementId, setAcceptedAgreementId] = React.useState(-99);

  const shouldShowAgreementDialog = () => {
    return acceptedAgreementId != currentAgreementId;
  };
  const recordAgreementAcceptance = () => {
    distiAuth.recordAgreementAcceptance(
      currentAgreementId,
      () => {
        console.log("Problem recording acceptance");
      },
      () => {
        setAcceptedAgreementId(currentAgreementId);
      }
    );
  };

  // styles
  const classes = useStyles();

  const doNewPassword = async (u, p) => {
    await distiAuth.applyNewPassword(
      u,
      p,
      resetNonce,
      e => {
        // Failure
        distiAuth.clearHashedPW();
        console.log(
          "Got exception from apply_new_password: " + JSON.stringify(e.response)
        );
        window.alert(
          "Unable to apply new password.  Try reset password again?"
        );
        setLoggedIn(false);
        window.location.replace("/"); //reload();
      },
      () => {
        // Success
        window.location.replace("/");
      }
    );
  };
  const doTheLogin = async (u, p) => {
    distiAuth.storeHashedPW(u, p);
    tryTheLogin();
  };
  const doResetPassword = async username => {
    distiAuth.requestPasswordReset(
      username,
      e => {
        /*failure*/
        console.log("Problem requesting password reset: " + e);
      },
      () => {
        /*success*/
        window.setTimeout(() => {
          alert("Check your email for the reset link.");
          handleLogout();
        }, 0);
      }
    );
  };
  const tryTheLogin = async () => {
    setTimeout(async () => {
      if (await distiAuth.getCredentialsUpdatedAsNeeded()) {
        createClient();
        username = distiAuth.getHashedPW().username;

        //userDataContext.setUserData()
        // TODO: This should really be using useContext, or another way to flow the user data down from the ancestors, but I'm out of time.
        //   See https://blog.logrocket.com/use-hooks-and-context-not-react-and-redux/ for a pattern
        //window.virtualClassroomUserData = distiAuth.getUserData();

        if (distiAuth.getUserData().acceptedAgreementId) {
          setAcceptedAgreementId(distiAuth.getUserData().acceptedAgreementId);
        }

        setLoggedIn(true);
      } else {
        console.log("Login failed.");
        setLoginFailMessage("Login failed.  Try again.");
        setTimeout(function() {
          setLoginFailMessage("");
        }, 3000);
      }
    }, 0);
  };

  const handleConfirmedLogout = () => {
    window.setTimeout(() => {
      if (window.confirm("Log out?")) {
        handleLogout();
      }
    }, 0);
  };
  const handleLogout = () => {
    distiAuth.clearHashedPW();
    setLoggedIn(false);
    window.location.replace(
      `https://instructor.${settings.Tenant}.disti.training`
    ); //reload();
  };

  React.useEffect(() => {
    // Try the login from the cache on startup
    setTimeout(tryTheLogin, 1);
  }, []);

  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (!mainPanel.current) return;

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleBgColorClick = bgColor => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
      //setMiniActive(false);
    } else {
      //setMiniActive(true);
    }
  };
  const useAllowedActionsForCurrentUser = () => {
    const userData = distiAuth.getUserData();

    return distiAuth.allowedActionsForRoles(userData.roles || []);
  };

  const storageName =
    "virtual-classroom-user-has-clicked-on-help-for-release-4";
  const recordHavingClickedOnHelp = () => {
    window.localStorage.setItem(storageName, true);
  };
  const getHasTheUserClickedOnHelp = () => {
    return Boolean(window.localStorage.getItem(storageName));
  };
  const WelcomeToNewVersion = () => {
    // Uncomment this to enable the version message popup:
    const [show, setShow] = React.useState(!getHasTheUserClickedOnHelp());
    //const [show, setShow] = React.useState(false);

    return (
      <SnackbarMui
        open={show}
        autoHideDuration={15000}
        onClose={() => {
          setShow(false);
        }}
        style={{ top: "60px" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity="success"
          onClose={() => {
            recordHavingClickedOnHelp();
            setShow(false);
          }}
        >
          Welcome to Release 4 of the DiSTI Schoolhouse Virtual Classroom.{" "}
          <br />
          Details of this release can be found by clicking the{" "}
          <HelpIcon style={{ color: "white" }} /> icon above and selecting
          &quot;Release Notes&quot;
        </Alert>
      </SnackbarMui>
    );
  };
  const InsideApollo = () => {
    const userData = distiAuth.getUserData();

    const allowedActions = useAllowedActionsForCurrentUser();
    const assignedMarkets = distiAuth.getUserData().markets;

    const routes = filterRoutes(originalRoutes, allowedActions);

    // The chat data only persists while running
    const [currentChatDataById, setCurrentChatDataById] = React.useState({});
    const setCurrentChatDataForId = (newData, studentId) => {
      if (studentId) {
        let rval = { ...currentChatDataById };
        rval[studentId] = newData;
        setCurrentChatDataById(rval);
      }
    };

    const AllowedActionsContextProvider = ({ children }) => {
      return (
        <distiAuth.AllowedActionsContext.Provider
          value={{ allowedActions, assignedMarkets }}
        >
          {children}
        </distiAuth.AllowedActionsContext.Provider>
      );
    };

    const CommonStudentDataContextProvider = ({ children }) => {
      return <>{children}</>;
      //return (<distiAuth.CommonStudentDataContext.Provider value={{currentChatDataById, setCurrentChatDataForId}} >{children}</distiAuth.CommonStudentDataContext.Provider>);
    };

    return (
      <AllowedActionsContextProvider>
        <CommonStudentDataContextProvider>
          <WelcomeToNewVersion />
          <Dialog
            open={shouldShowAgreementDialog()}
            fullWidth={false}
            maxWidth={"md"}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Privacy Policy</DialogTitle>
            <DialogContent>
              <PrivacyStatement />
              <Button
                style={{ width: "50px" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  recordAgreementAcceptance();
                }}
              >
                Accept
              </Button>
            </DialogContent>
          </Dialog>
          <div className={classes.wrapper}>
            <Sidebar
              routes={routes}
              logoText={""}
              logo={logo}
              image={image}
              handleDrawerToggle={handleDrawerToggle}
              open={true} //mobileOpen}
              color={color}
              bgColor={bgColor}
              miniActive={miniActive}
              onExpand={() => sidebarMinimize()}
              {...rest}
            />
            <span
              style={{
                width: "100%",
                float: "right",
                backgroundColor: "#103a6d", //"#b9b9b9",
                //position:"absolute",
                top: "0",
                height: "76px",
                color: "white", //"#103a6d",
                fontSize: "28pt",
                fontFamily: "Roboto",
                fontWeight: "bold",
                padding: "30px"
              }}
            >
              <div style={{ textAlign: "center" }}>
                Virtual Classroom Administration
              </div>
              <div
                style={{
                  display: "inline-flex",
                  textAlign: "right",
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  float: "right",
                  fontSize: "initial",
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start"
                }}
              >
                <Tooltip title="View documentation">
                  <IconButton
                    style={{ color: "white" }}
                    onClick={async () => {
                      try {
                        recordHavingClickedOnHelp();

                        const {
                          token,
                          payload
                        } = await distiAuth.getDocumentationCredentials();

                        const d = new Date();
                        d.setTime(
                          d.getTime() + 2 /*days*/ * 24 * 60 * 60 * 1000
                        );

                        const rolesString = payload.roles
                          ? payload.roles.join(",")
                          : "";
                        document.cookie = `${
                          settings.Tenant
                        }-InstructorDocsToken=${token}; expires=${d.toUTCString()};path=/help/`;
                        document.cookie = `${
                          settings.Tenant
                        }-InstructorDocsRoles=${rolesString}; expires=${d.toUTCString()};path=/help/`;

                        window.open(
                          `/help/instructor.html`,
                          `${settings.Tenant}-VirtualClassroomInstructorDocumentation`
                        );
                      } catch (e) {
                        console.log("Problem trying to view documentation:", e);
                      }
                    }}
                  >
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={<TrainerDetails />}>
                  <i>{userData.fullName || username}</i>
                </Tooltip>

                <Tooltip title="Log out...">
                  <IconButton
                    style={{ color: "white" }}
                    onClick={() => {
                      handleConfirmedLogout();
                    }}
                  >
                    <ExitIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </span>
            <div className={mainPanelClasses} ref={mainPanel}>
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              <div
                className={classes.content}
                style={{ marginTop: "0px", maxHeight: "initial" }}
              >
                <div className={classes.container}>
                  <Switch>
                    {getRoutes(routes)}
                    {/*allowedActions.viewLiveStudentSessions ?
                          <Redirect from="/admin" to="/admin/facilitator-dashboard" />
                        : <Redirect from="/admin" to="/admin/classes" />*/}
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </CommonStudentDataContextProvider>
      </AllowedActionsContextProvider>
    );
  };

  if (newPasswordMode) {
    // TODO: Decode props.location.search
    // to come up with email and nonce
    return (
      <NewPasswordPage
        email={(q.email || "").toLowerCase()}
        onApply={(u, p) => doNewPassword(u, p)}
      />
    );
  } else if (loggedIn) {
    return (
      <>
        <ApolloProvider client={client}>
          <InsideApollo />
        </ApolloProvider>
        <GlobalCallControl.CallDialog />
      </>
    );
  } else {
    return (
      <>
        <Snackbar
          place="bc"
          color="danger"
          message={loginFailMessage}
          open={!!loginFailMessage}
        />
        <LoginPage
          onApply={(u, p) => doTheLogin(u, p)}
          onResetPassword={u => doResetPassword(u)}
        />
      </>
    );
  }
}
/*                    <AdminNavbarLinks 
                            handleLogout={()=>handleLogout()}
                            rtlActive={false} />
*/
