//queryData.listStudents.items
const createNewStudent = async (studentList, createStudentFunc) => {
  try {
    while (true) {
      const emailAddress = window.prompt("Email address");
      if (emailAddress) {
        // Check for valid email address.  UTF-8 characters are not supported by some of the underlying systems, like (SES), so stop it here.
        if (!checkEmailFormatGood(emailAddress)) {
          window.alert(
            "Email address format not supported.  Please use a different email address."
          );
          continue;
        } else if (!checkEmailNotDuplicate(studentList, emailAddress)) {
          const entry = studentList.find(item => {
            return item.id == emailAddress;
          });
          window.alert(
            "This email address is already in the system for user: " +
              entry.id +
              " (" +
              entry.fullName +
              ")"
          );
          continue;
        } else {
          const fullName = window.prompt("Full Name") || "";

          await createStudentFunc({
            variables: { pk: emailAddress, fullName: fullName }
          });
          console.log("Created student");
          return emailAddress;
        }
      }
      break;
    }
  } catch (e) {
    console.log("Problem in create: " + e);
  }
};

const checkEmailFormatGood = candidateEmail => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(candidateEmail);
};
const checkEmailNotDuplicate = (studentList, candidateEmail) => {
  return !(
    studentList &&
    studentList.some(item => {
      return item.id == candidateEmail;
    })
  );
};

const getStudentsByPersonId = (studentList, candidatePersonId) => {
  if (!candidatePersonId)
  {
    return []; // Don't care about *matching* blanks
  }
  const candidate = (candidatePersonId || "").toLowerCase();

  // Return an array true if associated emails.
  // If associated emails exist, return them all.
  const foundWithPersonId = studentList.reduce((previousValue, currentValue) => { 
    if(currentValue.personId && currentValue.personId.toLowerCase() === candidate)
    {
      return [...previousValue, currentValue];
    }
    return previousValue;
  }, []
  );
  
  return foundWithPersonId;
}

const previewPhase3_2 = true; //window.location.search.includes("phase3.2=55") || window.location.hostname == "localhost" /* Temporarily making all 3.2 active when running local */;

export default {
  createNewStudent,
  checkEmailFormatGood,
  checkEmailNotDuplicate,
  getStudentsByPersonId,
  previewPhase3_2
};
