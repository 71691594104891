import React from "react";
import distiAuth from "disti-auth.js";

import Queries from "GraphQL/InstructorAccess.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import MapIcon from "@material-ui/icons/Map";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import BuildIcon from "@material-ui/icons/Build";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import ContentImage from "./ContentImage";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  entryLine: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px"
  }
};

const useStyles = makeStyles(styles);

const StringInput = ({
  className,
  valueData,
  valueKey,
  title,
  required,
  defaultValue,
  updateValue,
  disabled,
  inputValidCb,
  error
}) => {
  return (
    <Typography
      key={"typography" + valueKey}
      color="textSecondary"
      variant="h5"
      component="h2"
    >
      <TextField
        className={className}
        required={required}
        value={valueData[valueKey] || defaultValue || ""}
        id={valueKey}
        label={title}
        disabled={Boolean(disabled)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <MapIcon />
            </InputAdornment>
          ),
          error:
            (inputValidCb ? !inputValidCb(valueData[valueKey]) : false) || error
        }}
        onChange={e => {
          updateValue(valueKey, e.target.value);
        }}
      />
    </Typography>
  );
};

const FullContentEdit = ({
  open,
  createNew,
  onApply,
  onClose,
  initialValue,
  onValidIdCheck
}) => {
  const classes = useStyles();

  const [value, setValue] = React.useState({
    // Apply defaults:
    ...initialValue,
    startFileName: initialValue.startFileName || "start_all.bat",
    stopFileName: initialValue.stopFileName || "stop_all.bat"
  });

  let prettyAvailableSettings = "";
  try {
    prettyAvailableSettings = JSON.stringify(
      JSON.parse(value.availableSettings),
      null,
      2
    );
  } catch (e) {
    console.log("Problem parsing availableSettings:", e);
  }

  const updateValue = (key, value) => {
    setValue(oldValue => {
      return { ...oldValue, [key]: value };
    });
  };

  const handleCancel = () => {
    onClose();
  };
  const handleApply = () => {
    onApply({
      createNew,
      value: {
        pk: value.id,
        name: value.name || "",
        description: value.description || "",
        highlights: value.highlights || "",
        availableSettings: value.availableSettings || "",
        lessonPassCriteria: value.lessonPassCriteria || "",
        startFileName: value.startFileName || "",
        stopFileName: value.stopFileName || "",
        supportsLessonModification: value.supportsLessonModification || false,
        executableNameForRunningCheck:
          value.executableNameForRunningCheck || "",
        tags: value.tags || [],
        zipLastUpdated: value.zipLastUpdated || "0",
        whoLastUpdatedZip: value.whoLastUpdatedZip || ""
      }
    });
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth={true}
      maxWidth={"lg"}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <ContentImage
          id={value.id}
          style={{
            maxWidth: "150px",
            maxHeight: "150px",
            position: "absolute",
            top: "25px",
            zIndex: "999"
          }}
        />

        <CloseIcon
          style={{ position: "absolute", right: "25px", zIndex: "1" }}
          fontSize="large"
          onClick={handleCancel}
        />
      </DialogTitle>
      <DialogContent>
        <Card className={classes.root}>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <EditIcon />
            </CardIcon>
            <h1 className={classes.cardIconTitle}>
              {createNew ? "Add New Content" : "Edit Content"}
            </h1>
          </CardHeader>

          <CardContent>
            <GridContainer>
              <GridItem xs={6}>
                <StringInput
                  disabled={!createNew}
                  updateValue={updateValue}
                  className={classes.entryLine}
                  valueData={value}
                  valueKey="id"
                  title="Content ID"
                  inputValidCb={createNew ? onValidIdCheck : null}
                />
                <StringInput
                  updateValue={updateValue}
                  className={classes.entryLine}
                  valueData={value}
                  valueKey="name"
                  title="Content Name"
                  error={!value.name}
                />
                <StringInput
                  updateValue={updateValue}
                  className={classes.entryLine}
                  valueData={value}
                  valueKey="description"
                  title="Description"
                />                
                <StringInput
                  updateValue={updateValue}
                  className={classes.entryLine}
                  valueData={value}
                  valueKey="executableNameForRunningCheck"
                  title="Executable for running check"
                  error={
                    !createNew &&
                    (!value.executableNameForRunningCheck ||
                      !value.executableNameForRunningCheck.endsWith(".exe"))
                  }
                />
                <StringInput
                  updateValue={updateValue}
                  className={classes.entryLine}
                  valueData={value}
                  valueKey="highlights"
                  title="Highlights"
                />
                <StringInput
                  updateValue={updateValue}
                  className={classes.entryLine}
                  valueData={value}
                  valueKey="tags"
                  title="Tags"
                />
              </GridItem>
              <GridItem xs={6}>
                <StringInput
                  updateValue={updateValue}
                  className={classes.entryLine}
                  valueData={value}
                  valueKey="startFileName"
                  title="Start File"
                  error={!value.startFileName}
                />
                <StringInput
                  updateValue={updateValue}
                  className={classes.entryLine}
                  valueData={value}
                  valueKey="stopFileName"
                  title="Stop File"
                  error={!value.stopFileName}
                />          
                <span style={{ flexGrow: "2", textAlign: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={value.supportsLessonModification}
                        name="supportsLessonModification"
                        onChange={event => {
                          updateValue("supportsLessonModification", event.target.checked);
                        }}
                      />
                    }
                    label="Supports Lesson Modification"
                    labelPlacement="top"
                    disabled
                  />
                </span>                     
                <Typography
                  key="typographyAvailableSettings"
                  color="textSecondary"
                  variant="h5"
                  component="h2"
                >
                  <TextField
                    className={classes.entryLine}
                    required={false}
                    value={prettyAvailableSettings}
                    id="availableSettings"
                    label="Available Settings"
                    multiline={true}
                    rows={10}
                    disabled={true}
                  />
                </Typography>
              </GridItem>
            </GridContainer>
          </CardContent>

          <CardActions></CardActions>
        </Card>

        <Button
          color="primary"
          style={{
            float: "left",
            marginLeft: "100px",
            marginTop: "20px",
            marginBottom: "20px"
          }}
          onClick={handleApply}
          disabled={
            !value.name ||
            !value.startFileName ||
            !value.stopFileName ||
            (!createNew &&
              (!value.executableNameForRunningCheck ||
                !value.executableNameForRunningCheck.endsWith(".exe")))
          }
        >
          Confirm
        </Button>
        <Button
          style={{
            float: "right",
            marginRight: "100px",
            marginTop: "20px",
            marginBottom: "20px"
          }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default FullContentEdit;
