import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-6";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import ListIcon from "@material-ui/icons/List";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SettingsIcon from "@material-ui/icons/Settings";
import SaveIcon from "@material-ui/icons/SaveAlt";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import XLSX from "xlsx";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";

import MonthPicker from "react-month-picker";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import distiAuth from "disti-auth.js";

import VideoHistory from "views/Pages/VideoHistory.js";


const useStyles = makeStyles(theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

const convertToNumberWithDefault = (s, d) => {
  d = d || 0;
  try {
    const rval = parseInt(s);
    if (isNaN(rval)) return d;
    return rval;
  } catch (e) {}
  return d;
};
const PricingDialog = ({ ...dialogParams }) => {
  const [usersPerMonth, setUsersPerMonth] = React.useState(500);
  const [totalHoursPerMonth, setTotalHoursPerMonth] = React.useState(1200);
  const [lastInputs, setLastInputs] = React.useState("");
  const [resultString, setResultString] = React.useState("");
  const [calculating, setCalculating] = React.useState(false);
  const resultsMatchInputs =
    "" + usersPerMonth + totalHoursPerMonth == lastInputs;

  const usersPerMonthError = usersPerMonth % 25 !== 0;
  const totalHoursPerMonthError = totalHoursPerMonth % 100 !== 0;

  const classes = useStyles();
  return (
    <Dialog {...dialogParams}>
      <DialogTitle id="form-dialog-title">
        Schoolhouse Pricing
        <CloseIcon
          style={{ position: "absolute", right: "25px", zIndex: "1" }}
          fontSize="large"
          onClick={dialogParams.onClose}
        />
      </DialogTitle>
      <DialogContent style={{ height: "15em" }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <TextField
              style={{ width: "90%" }}
              className={classes.entryLine}
              value={usersPerMonth}
              id="usersPerMonth"
              label="Users Per Month (multiples of 25)"
              error={usersPerMonthError}
              onChange={e => {
                setUsersPerMonth(convertToNumberWithDefault(e.target.value, 0));
              }}
            />
            <br />
            <br />
            <br />
            <TextField
              style={{ width: "90%" }}
              className={classes.entryLine}
              value={totalHoursPerMonth}
              id="totalHoursPerMonth"
              label="Total Hours Per Month (multiples of 100)"
              error={totalHoursPerMonthError}
              onChange={e => {
                setTotalHoursPerMonth(
                  convertToNumberWithDefault(e.target.value)
                );
              }}
            />
            <br />
          </Grid>
          <Grid item xs={2}>
            <br />
            <br />
            <Button
              round
              color="primary"
              disabled={
                resultsMatchInputs ||
                usersPerMonthError ||
                totalHoursPerMonthError
              }
              onClick={async () => {
                console.log("Content clicked");
                setCalculating(true);
                try {
                  const result = await distiAuth.getSchoolhousePricing(
                    usersPerMonth,
                    totalHoursPerMonth
                  );
                  setResultString(result);
                  setLastInputs("" + usersPerMonth + totalHoursPerMonth);
                } catch (e) {
                  console.log("Problem calculating:", e);
                }
                setCalculating(false);
              }}
            >
              Calculate
            </Button>
          </Grid>
          <Grid item xs={4}>
            {resultString && resultsMatchInputs ? (
              resultString.map(item => {
                return (
                  <span key={item.name}>
                    <br />
                    <Typography>
                      <>
                        <span>{item.name}:</span>
                        <span style={{ float: "right" }}>
                          ${item.value} (USD per month)
                        </span>
                      </>
                    </Typography>
                  </span>
                );
              })
            ) : calculating ? (
              <>
                <br />
                <br />
                <CircularProgress />
              </>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default PricingDialog;
