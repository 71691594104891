import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-6";
import Queries from "GraphQL/InstructorAccess.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import MapIcon from "@material-ui/icons/Map";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import BuildIcon from "@material-ui/icons/Build";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { isNullLiteral } from "@babel/types";

import distiAuth from "disti-auth.js";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  entryLine: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px"
  }
};

const useStyles = makeStyles(styles);

// {
//   uploadLocalZipFile({
//     filename: e.currentTarget.files[0],
//     contentId: currentEntryData.id,
//     onStart: (arg) => {
//       setFileUploadStatus(arg);
//       console.log("onStart", arg);
//     },
//     onFail: (arg) => {
//       setFileUploadStatus(null);
//       console.log("onFail", arg);
//     }, // TODO: Show the fail status
//     onProgress: (arg) => {
//       setFileUploadStatus(arg);
//       console.log("onProgress", arg);
//     },
//     onFinish: (arg) => {
//       setFileUploadStatus(arg);
//       console.log("onFinish", arg);
//     }, //TODO: Show some kind of success
//   });
// }

// const handleUploadCancel = () => {
//   // TODO: Actually cancel
//   setFileUploadStatus(null);
//   setcurrentEntryData(null);
// };

const useDoActualUpload = ({
  filename,
  metadata,
  onClose,
  closeOnFinish,
  onSuccess
}) => {
  const [progress, setProgress] = React.useState(0);
  const cancelCallbackRef = React.useRef(null);
  const [message, setMessage] = React.useState("");

  const cancel = async () => {
    if (cancelCallbackRef.current) {
      cancelCallbackRef.current();
    }
  };
  React.useEffect(() => {
    const doIt = async () => {
      try {
        setProgress(0.0001);

        const postData = JSON.parse(
          JSON.stringify(
            await distiAuth.getContentUploadZipUrl({
              contentId: metadata.id
            })
          )
        );

        const data = new FormData();

        //postData.fields.key = postData.fields.key.replace("${filename}", uniqueFileName)

        Object.keys(postData.fields).forEach(key =>
          data.append(key, postData.fields[key])
        );

        data.append("file", filename);

        const CancelToken = axios.CancelToken;
        const cancelSource = CancelToken.source();
        cancelCallbackRef.current = () => cancelSource.cancel("CANCELED");

        const result = await axios.post(postData.url, data, {
          cancelToken: cancelSource.token,
          // This progress shows early success :-(   https://github.com/axios/axios/issues/3469
          onUploadProgress: e => {
            setProgress(Math.round((e.loaded * 100) / e.total));
          }
        });
        cancelCallbackRef.current = null;
        console.log("Got result:", result);
        if (onSuccess) onSuccess();

        setMessage("Upload completed successfully");

        if (closeOnFinish) {
          await sleep(1000);
          onClose();
        }
      } catch (e) {
        if (e.message && e.message == "CANCELED") {
          setMessage("Upload canceled");
        } else {
          console.log("Failed to upload: ", e);
          setMessage("Upload failed");
        }
      }
      cancelCallbackRef.current = null;
    };
    doIt();
  }, [filename]);

  return { message, progress, cancel };
};

export default function ContentZipActivelyUploading({
  filename,
  metadata,
  onClose,
  onSuccess
}) {
  const { message, progress, cancel } = useDoActualUpload({
    filename,
    metadata,
    onClose,
    onSuccess,
    closeOnFinish: false
  });

  return (
    <Dialog
      open={true}
      onClose={null}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Uploading {filename.name}
        {!message ? (
          <CloseIcon
            style={{ position: "absolute", right: "25px", zIndex: "1" }}
            fontSize="large"
            onClick={cancel}
          />
        ) : null}
      </DialogTitle>
      <DialogContent>
        <>
          {message ? (
            <>
              {message}
              <br />
              <br />

              <Button
                color="primary"
                onClick={onClose}
                style={{ textAlign: "center" }}
              >
                OK
              </Button>
            </>
          ) : (
            <LinearProgress
              variant={progress < 99 ? "determinate" : "indeterminate"}
              value={progress}
            />
          )}
        </>
      </DialogContent>
    </Dialog>
  );
}
