import React from "react";

import distiAuth from "disti-auth.js";

const useInterval = (callback, delay, startNow) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (startNow) {
      tick(); // Start with an immediate call
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const globalChatDataContainerById = {};

const addEntryIfNeeded = id => {
  if (!globalChatDataContainerById[id]) {
    globalChatDataContainerById[id] = {
      triggers: [],
      latestData: []
    };
  }
};

const registerTrigger = (triggerFunc, userId) => {
  addEntryIfNeeded(userId);
  const uniqueId = "" + Math.random();

  globalChatDataContainerById[userId].triggers.push({
    uniqueId,
    triggerFunc
  });
  return uniqueId;
};
const unregisterTrigger = (uniqueId, userId) => {
  const startingVal = globalChatDataContainerById[userId].triggers;
  globalChatDataContainerById[userId].triggers = startingVal.filter(
    item => item.uniqueId != uniqueId
  );
};
const callTrigger = userId => {
  const userIdTriggerList = globalChatDataContainerById[userId].triggers;
  userIdTriggerList.forEach(item => {
    item.triggerFunc();
  });

  /*
    const allTriggerList = globalChatDataContainer.chatDataChangeTriggersById["ALL"] || []
    allTriggerList.forEach((item)=>{item.triggerFunc()})            
    */
};

const lastReadCountById = {};

const currentUnreadCountById = {};

const useChatData = ({
  studentName,
  classId,
  pollInterval = 5000,
  passive = false
}) => {
  addEntryIfNeeded(studentName);

  const username = distiAuth.getUsername();

  const [, triggerRender] = React.useState(null);

  const triggerCallback = React.useCallback(() => {
    triggerRender({});
  });

  React.useEffect(() => {
    // Register the setState by userId
    const uniqueId = registerTrigger(triggerCallback, studentName);
    return () => {
      unregisterTrigger(uniqueId, studentName);
    };
  }, [studentName, triggerCallback]);

  const callback = async () => {
    // Only non passive version will actually get the data
    if (passive) {
      return;
    }

    const newData = await distiAuth.getCurrentChatDataByStudent(
      studentName,
      classId
    );
    if (newData) {
      addEntryIfNeeded(studentName);
      // Check if it needs triggering
      const oldData = globalChatDataContainerById[studentName].latestData || [];
      if (JSON.stringify(oldData) != JSON.stringify(newData)) {
        globalChatDataContainerById[studentName].latestData = newData;
        callTrigger(studentName);
      }
    } else {
      console.log("No data");
    }
  };
  useInterval(callback, pollInterval, true);

  const currentData = globalChatDataContainerById[studentName].latestData || [];
  const allOtherCount = currentData.filter(item => item.who != username).length;
  const unreadCount = allOtherCount - (lastReadCountById[studentName] || 0);
  currentUnreadCountById[studentName] = unreadCount;

  const connected = true;
  const clearUnread = () => {
    lastReadCountById[studentName] = allOtherCount;
    currentUnreadCountById[studentName] = 0;
  };
  const sendData = async newMessage => {
    const newData = await distiAuth.addToCurrentChatDataByStudent(
      studentName,
      classId,
      newMessage
    );
    if (newData) {
      globalChatDataContainerById[studentName].latestData = newData || [];
      callTrigger(studentName);
      return true;
    }
    return false;
  };

  return {
    currentData,
    allCount: currentData.length,
    unreadCount,
    connected,
    sendData,
    clearUnread
  };
};

const getCurrentUnreadCountById = () => {
  return currentUnreadCountById;
};

export default {
  useChatData,
  getCurrentUnreadCountById
};
