import React from "react";
import ReactTable from "react-table-6";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardActions from "@material-ui/core/CardActions";
import CardBody from "components/Card/CardBody.js";
import CardContent from "@material-ui/core/CardContent";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import distiAuth from "disti-auth";
import EditIcon from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import Queries from "GraphQL/InstructorAccess.js";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { Input, MenuItem } from "@material-ui/core";


const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    entryLine: {
        width: "100%",
        marginTop: "15px",
        marginBottom: "15px"
    },
    shortEntryLine: {
      marginTop: "15px",
      marginBottom: "15px"
    },
});
  
export default function ClassLessonModEdit({
    open,
    onApply,
    onClose,
    initialValue,
}){
    if (!open) {
        return "";
    }
    
    const tableStyle = {
        height: "fit-content"
    }
    
    const classes = useStyles();

    const columns = [
        {
            Header: "Content",
            accessor: "contentName",
        },
        {
            Header: "Lesson Modification",
            accessor: "lessonMod"
        }
    ]

    let {
        loading: modsLoading,
        data: modsData
    } = useQuery( Queries.LESSON_MOD.ALL );

    let {
        loading: contentLoading,
        data: contentData
    } = useQuery( Queries.CONTENT.ALL );

    const market = React.useMemo(()=>{return initialValue.items.market}, [initialValue.items.market]);
    const currentClassContentList = React.useMemo(() => {return [...initialValue.items.content]}, [JSON.stringify(initialValue.items.content)]);

    const [currentClassLessonModList, setCurrentClassLessonModList] = React.useState( initialValue.items.lessonMods ? JSON.parse(initialValue.items.lessonMods) : {} );        

    const handleApply = React.useCallback(() => {
        onApply(JSON.stringify(currentClassLessonModList));
        onClose();
    }, [currentClassLessonModList, onApply, onClose]);

    const handleCancel = onClose;

    let constructedLessonModData = React.useMemo(()=>{ 
        const constructedData = [];
        if(!modsLoading && 
            modsData && 
            modsData.listLessonMod && 
            modsData.listLessonMod.items && 
            !contentLoading && 
            contentData) {
            for (let content in currentClassContentList) {
                let rowData = {};
                
                rowData.content = currentClassContentList[content];
                for (let contentLookup in contentData.listContent.items) {
                    if(contentData.listContent.items[contentLookup].id == currentClassContentList[content]) {
                        rowData.contentName = contentData.listContent.items[contentLookup].name;
                    }
                }

                rowData.currentLessonModification = currentClassLessonModList[currentClassContentList[content]] || "---";
                rowData.currentLessonModificationName = "";

                rowData.options = [];
                let availableLessonModifications = [];
                let availableLessonModificationNames = [];
                for (let modificationsLookup in modsData.listLessonMod.items) {
                if((modsData.listLessonMod.items[modificationsLookup].content == currentClassContentList[content]) 
                    && ((modsData.listLessonMod.items[modificationsLookup].availableMarkets).includes(market))) {
                        availableLessonModifications.push(modsData.listLessonMod.items[modificationsLookup].id);
                        availableLessonModificationNames.push( modsData.listLessonMod.items[modificationsLookup].name || "N/A")
                    }

                if(modsData.listLessonMod.items[modificationsLookup].id == rowData.currentLessonModification) {
                    rowData.currentLessonModificationName = modsData.listLessonMod.items[modificationsLookup].name || "N/A";
                }
                }
                for (let option in  availableLessonModifications) {
                rowData.options.push(
                    {
                        label: availableLessonModificationNames[option],
                        id: availableLessonModifications[option],
                        content: rowData.content
                    }
                );
                }

                rowData.lessonMod = (<>
                <Autocomplete
                    id={rowData.contentName}
                    options={rowData.options}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.label === value.label || value.label === "" }
                    sx={{width:500}}
                    renderInput={(params) => <TextField {...params} variant="standard" label="Modification Name" />}
                    onChange={(event, newValue, details) => {
                    // console.log("Details: ", details);
                    console.log("newValue: ", newValue);
                    // If row data was static; we don't need to calc every time we draw
                    if(!newValue) {
                        let temp = currentClassLessonModList;
                        delete temp[rowData.content];
                        setCurrentClassLessonModList(temp);
                    }
                    else {
                        let temp = currentClassLessonModList;
                        temp[rowData.content] = newValue.id;
                        setCurrentClassLessonModList(temp);
                    }
                    }}
                    value={{ label: rowData.currentLessonModificationName }}
                />
                </>)

                constructedData.push(rowData);
            }
        }
        return constructedData;
    },[modsLoading, 
       modsData, 
       contentLoading, 
       contentData, 
       currentClassContentList, 
       currentClassLessonModList, 
       setCurrentClassLessonModList]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCancel}
                fullWidth={true}
                maxWidth={"lg"}
                aria-labelledby="form-dialog-title"
            >
            <DialogTitle id="form-dialog-title">
            <CloseIcon
                style={{ position: "absolute", right: "25px", zIndex: "1" }}
                fontSize="large"
                onClick={handleCancel}
            />
            </DialogTitle>
            <DialogContent>
            <GridContainer>
            <GridItem xs={12}>
                <Card className={classes.root}>
                <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                    <EditIcon />
                    </CardIcon>
                    <h1 className={classes.cardIconTitle}>{"Select Lesson Modifications"}</h1>
                </CardHeader>

                <CardContent>
                    <Typography color="textSecondary" variant="h5" component="h2">
                        <ReactTable
                        data={constructedLessonModData}
                        style={tableStyle}
                        filterable={false}
                        columns={columns}
                        pageSize={ constructedLessonModData.length}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight"
                        />
                    </Typography>
                    <br />
                </CardContent>

                <CardActions></CardActions>
                </Card>
            </GridItem>
            </GridContainer>
            <Button
                color="primary"
                style={{
                    float: "left",
                    marginLeft: "100px",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}
                onClick={handleApply}
            >
            Confirm
            </Button>
            <Button
                style={{
                    float: "right",
                    marginRight: "100px",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}
                onClick={handleCancel}
            >
            Cancel
            </Button>
            </DialogContent>
            </Dialog>
        </>
    );
}
