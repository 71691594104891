import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Badge from "@material-ui/core/Badge";
import FormGroup from "@material-ui/core/FormGroup";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InstructorChat from "components/LiveClassModal/InstructorChat.js";
import LiveClassList from "./LiveClassList";
import LiveStudentDashboardDetail from "./LiveStudentDashboardDetail.js";
import ClassSelectMenu from "./ClassSelectMenu.js";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ClassIcon from "@material-ui/icons/Class";
import Typography from "@material-ui/core/Typography";

import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/client";

import Queries from "GraphQL/InstructorAccess.js";
import Helpers from "GraphQL/SubscriptionHelpers.js";

import moment from "moment";

import defaultContentThumbnail from "../../thumbnail_placeholder.jpg";
import defaultUserThumbnail from "../../default_user_image.jpg";

import distiAuth from "disti-auth.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

//From http://country.io/names.json
const countryNames = {
  BD: "Bangladesh",
  BE: "Belgium",
  BF: "Burkina Faso",
  BG: "Bulgaria",
  BA: "Bosnia and Herzegovina",
  BB: "Barbados",
  WF: "Wallis and Futuna",
  BL: "Saint Barthelemy",
  BM: "Bermuda",
  BN: "Brunei",
  BO: "Bolivia",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BT: "Bhutan",
  JM: "Jamaica",
  BV: "Bouvet Island",
  BW: "Botswana",
  WS: "Samoa",
  BQ: "Bonaire, Saint Eustatius and Saba ",
  BR: "Brazil",
  BS: "Bahamas",
  JE: "Jersey",
  BY: "Belarus",
  BZ: "Belize",
  RU: "Russia",
  RW: "Rwanda",
  RS: "Serbia",
  TL: "East Timor",
  RE: "Reunion",
  TM: "Turkmenistan",
  TJ: "Tajikistan",
  RO: "Romania",
  TK: "Tokelau",
  GW: "Guinea-Bissau",
  GU: "Guam",
  GT: "Guatemala",
  GS: "South Georgia and the South Sandwich Islands",
  GR: "Greece",
  GQ: "Equatorial Guinea",
  GP: "Guadeloupe",
  JP: "Japan",
  GY: "Guyana",
  GG: "Guernsey",
  GF: "French Guiana",
  GE: "Georgia",
  GD: "Grenada",
  GB: "United Kingdom",
  GA: "Gabon",
  SV: "El Salvador",
  GN: "Guinea",
  GM: "Gambia",
  GL: "Greenland",
  GI: "Gibraltar",
  GH: "Ghana",
  OM: "Oman",
  TN: "Tunisia",
  JO: "Jordan",
  HR: "Croatia",
  HT: "Haiti",
  HU: "Hungary",
  HK: "Hong Kong",
  HN: "Honduras",
  HM: "Heard Island and McDonald Islands",
  VE: "Venezuela",
  PR: "Puerto Rico",
  PS: "Palestinian Territory",
  PW: "Palau",
  PT: "Portugal",
  SJ: "Svalbard and Jan Mayen",
  PY: "Paraguay",
  IQ: "Iraq",
  PA: "Panama",
  PF: "French Polynesia",
  PG: "Papua New Guinea",
  PE: "Peru",
  PK: "Pakistan",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PM: "Saint Pierre and Miquelon",
  ZM: "Zambia",
  EH: "Western Sahara",
  EE: "Estonia",
  EG: "Egypt",
  ZA: "South Africa",
  EC: "Ecuador",
  IT: "Italy",
  VN: "Vietnam",
  SB: "Solomon Islands",
  ET: "Ethiopia",
  SO: "Somalia",
  ZW: "Zimbabwe",
  SA: "Saudi Arabia",
  ES: "Spain",
  ER: "Eritrea",
  ME: "Montenegro",
  MD: "Moldova",
  MG: "Madagascar",
  MF: "Saint Martin",
  MA: "Morocco",
  MC: "Monaco",
  UZ: "Uzbekistan",
  MM: "Myanmar",
  ML: "Mali",
  MO: "Macao",
  MN: "Mongolia",
  MH: "Marshall Islands",
  MK: "Macedonia",
  MU: "Mauritius",
  MT: "Malta",
  MW: "Malawi",
  MV: "Maldives",
  MQ: "Martinique",
  MP: "Northern Mariana Islands",
  MS: "Montserrat",
  MR: "Mauritania",
  IM: "Isle of Man",
  UG: "Uganda",
  TZ: "Tanzania",
  MY: "Malaysia",
  MX: "Mexico",
  IL: "Israel",
  FR: "France",
  IO: "British Indian Ocean Territory",
  SH: "Saint Helena",
  FI: "Finland",
  FJ: "Fiji",
  FK: "Falkland Islands",
  FM: "Micronesia",
  FO: "Faroe Islands",
  NI: "Nicaragua",
  NL: "Netherlands",
  NO: "Norway",
  NA: "Namibia",
  VU: "Vanuatu",
  NC: "New Caledonia",
  NE: "Niger",
  NF: "Norfolk Island",
  NG: "Nigeria",
  NZ: "New Zealand",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  CK: "Cook Islands",
  XK: "Kosovo",
  CI: "Ivory Coast",
  CH: "Switzerland",
  CO: "Colombia",
  CN: "China",
  CM: "Cameroon",
  CL: "Chile",
  CC: "Cocos Islands",
  CA: "Canada",
  CG: "Republic of the Congo",
  CF: "Central African Republic",
  CD: "Democratic Republic of the Congo",
  CZ: "Czech Republic",
  CY: "Cyprus",
  CX: "Christmas Island",
  CR: "Costa Rica",
  CW: "Curacao",
  CV: "Cape Verde",
  CU: "Cuba",
  SZ: "Swaziland",
  SY: "Syria",
  SX: "Sint Maarten",
  KG: "Kyrgyzstan",
  KE: "Kenya",
  SS: "South Sudan",
  SR: "Suriname",
  KI: "Kiribati",
  KH: "Cambodia",
  KN: "Saint Kitts and Nevis",
  KM: "Comoros",
  ST: "Sao Tome and Principe",
  SK: "Slovakia",
  KR: "South Korea",
  SI: "Slovenia",
  KP: "North Korea",
  KW: "Kuwait",
  SN: "Senegal",
  SM: "San Marino",
  SL: "Sierra Leone",
  SC: "Seychelles",
  KZ: "Kazakhstan",
  KY: "Cayman Islands",
  SG: "Singapore",
  SE: "Sweden",
  SD: "Sudan",
  DO: "Dominican Republic",
  DM: "Dominica",
  DJ: "Djibouti",
  DK: "Denmark",
  VG: "British Virgin Islands",
  DE: "Germany",
  YE: "Yemen",
  DZ: "Algeria",
  US: "United States",
  UY: "Uruguay",
  YT: "Mayotte",
  UM: "United States Minor Outlying Islands",
  LB: "Lebanon",
  LC: "Saint Lucia",
  LA: "Laos",
  TV: "Tuvalu",
  TW: "Taiwan",
  TT: "Trinidad and Tobago",
  TR: "Turkey",
  LK: "Sri Lanka",
  LI: "Liechtenstein",
  LV: "Latvia",
  TO: "Tonga",
  LT: "Lithuania",
  LU: "Luxembourg",
  LR: "Liberia",
  LS: "Lesotho",
  TH: "Thailand",
  TF: "French Southern Territories",
  TG: "Togo",
  TD: "Chad",
  TC: "Turks and Caicos Islands",
  LY: "Libya",
  VA: "Vatican",
  VC: "Saint Vincent and the Grenadines",
  AE: "United Arab Emirates",
  AD: "Andorra",
  AG: "Antigua and Barbuda",
  AF: "Afghanistan",
  AI: "Anguilla",
  VI: "U.S. Virgin Islands",
  IS: "Iceland",
  IR: "Iran",
  AM: "Armenia",
  AL: "Albania",
  AO: "Angola",
  AQ: "Antarctica",
  AS: "American Samoa",
  AR: "Argentina",
  AU: "Australia",
  AT: "Austria",
  AW: "Aruba",
  IN: "India",
  AX: "Aland Islands",
  AZ: "Azerbaijan",
  IE: "Ireland",
  ID: "Indonesia",
  UA: "Ukraine",
  QA: "Qatar",
  MZ: "Mozambique"
};

const useStyles = makeStyles(theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  currentClassDetails: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    textAlign: "center",
    flexGrow: 1,
    minWidth: "3em",
    flexShrink: 0
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16)
    }
  },
  paper: {
    padding: theme.spacing(2),
    //textAlign: 'center',
    color: theme.palette.text.secondary
  },
  formControl: {
    //margin: theme.spacing(1),
    marginLeft: "10px",
    marginBottom: "10px",
    minWidth: 300
  }
}));
const ThickLinearProgress = withStyles({
  root: {
    height: 20,
    borderRadius: 20
  },
  bar: {
    borderRadius: 20
  },
  colorPrimary: {
    backgroundColor: "#a4a8ab"
  },
  barColorPrimary: {
    backgroundColor: "#40bf40"
  }
})(LinearProgress);

const contentIdInClassItem = function(contentId, classItem) {
  try {
    return !!classItem.content.find(id => contentId == id);
  } catch (e) {
    return false;
  }
};

const FacilitatorDashboard = params => {
  const [compactStudentView, setCompactStudentView] = React.useState(true);

  const [doScroll, setDoScroll] = React.useState(true);

  const [classId, setClassId] = React.useState(
    distiAuth.globalInstructorSelectedClassId
  ); //"c2899b88-67ba-4b24-b43a-46c2935e1eb3") // TODO: Add selection

  React.useEffect(() => {
    distiAuth.globalInstructorSelectedClassId = classId;
  }, [classId]);

  const { loading: contentAllClasses, data: allClassesData } = useQuery(
    Queries.CLASSES.ALL
  );
  const { loading: contentLoading, data: contentData } = useQuery(
    Queries.CONTENT.ALL
  );

  const { loading: allStudentsLoading, data: allStudentsData } = Helpers.useStudentQuery();

  const {
    loading: userStatusLoading,
    data: userStatusData
  } = Helpers.useSubscribedUserStatusQuery();

  const { loading: loadingMarketData, data: marketQueryData } = useQuery(
    Queries.MARKETS.ALL
  );

  let marketNamesById = {};
  if (marketQueryData && marketQueryData.listMarkets.items) {
    marketQueryData.listMarkets.items.forEach(item => {
      marketNamesById[item.id] = item.name;
    });
  }

  let contentNameById = {};
  if (contentData && contentData.listContent) {
    contentData.listContent.items.forEach(item => {
      contentNameById[item.id] = item.name;
    });
  }
  const errorSub = false;

  const currentClass =
    allClassesData && allClassesData.listClasses
      ? allClassesData.listClasses.items.filter(
          classItem => classItem.id === classId
        )[0]
      : null;
  const classRegionId = currentClass ? currentClass.region : null;
  // Trigger adding of the class facilitator streamingurl to the system
  React.useEffect(() => {
    if (classId && classRegionId) {
      distiAuth.getStreamingURL(
        {
          region: classRegionId,
          classId,
          cc: "---",
          facilitator: true,
          reportGroup: "results"
        },
        e => {
          console.log("Problem getting StreamingURL: " + e);
        },
        s => {
          console.log(
            "Got streaming url, but we will not use it directly... we will get it as class data"
          );
        }
      );
    }
  }, [classId, classRegionId]);

  const [showActiveOnly, setShowActiveOnly] = React.useState(true);
  const [studentSearch, setStudentSearch] = React.useState("");

  if (errorSub) {
    console.log("Got errorSub: " + JSON.stringify(errorSub));
  }
  let studentList = [];
  let className = "";
  let classRegion = "";
  if (
    classId &&
    userStatusData &&
    allStudentsData &&
    allStudentsData.listStudents &&
    allClassesData &&
    allClassesData.listClasses
  ) {
    // Merging three chunks of data:
    // 	1) The list of all students (to get the full name)
    // 	2) The list students in the desired class
    //	3) The active information for each student

    let regionNames = null;
    regionNames = distiAuth.getRegionDataItems().reduce((newMap, item) => {
      newMap[item.id] = item.name;
      return newMap;
    }, {});
    //console.log("Got regionNames:"+JSON.stringify(regionNames));

    // Get the list of students by ID from the class
    //const currentClass = allClassesData.listClasses.items.filter( classItem => classItem.id === classId )[0];

    className = currentClass.description;
    classRegion = regionNames[currentClass.region] || currentClass.region;

    const classStudentsWithFacilitator = [...currentClass.students, classId]; // Add the special facilitator

    studentList = classStudentsWithFacilitator.reduce(
      (resultList, studentId) => {
        let studentData = allStudentsData.listStudents.items.find(
          entry => entry.id === studentId
        );
        if (!studentData && studentId == classId) {
          // Special facilitator
          studentData = {
            id: studentId,
            fullName: "Facilitator"
          };
        }
        // Note that we can have a user assigned to multiple classes, but they will only be in one at a time
        let statusData = userStatusData.listUserStatus
          ? userStatusData.listUserStatus.items.find(
              userItem =>
                userItem.userId === studentId &&
                userItem.currentClass === classId
            )
          : null;

        if (
          statusData &&
          statusData.deleteStatusAt &&
          parseInt(statusData.deleteStatusAt) <= moment().unix()
        ) {
          //console.log("Dropping "+studentId+" because it is stale. statusData.deleteStatusAt is "+statusData.deleteStatusAt)
          statusData = null; // Don't show this entry.  It is stale and likely being deleted right now.
        }

        let connectedTime = "--";
        let elapsedTime = "---";
        try {
          //connectedTime = statusData && statusData.loginTime && statusData.updateTime ? (parseFloat(statusData.updateTime) - parseFloat(statusData.loginTime)) : "---"

          if (statusData && statusData.loginTime) {
            let diff = moment.duration(
              moment().diff(parseFloat(statusData.loginTime) * 1000)
            );
            connectedTime = parseInt(diff.as("minutes")) + " minutes ago";
          }

          if (
            statusData &&
            statusData.elapsedTime &&
            statusData.elapsedTime.split(":").length == 3
          ) {
            // TODO: Turn this into seconds before sending here
            let s = statusData.elapsedTime.split(".")[0].split(":");
            elapsedTime =
              parseInt(s[0]) * 60 + parseInt(s[1]) + " minutes elapsed";
          }
        } catch (e) {
          console.log("Issue calculating time: " + e);
        }
        // if (statusData)
        // {
        // console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^statusData: "+JSON.stringify(statusData))
        // }
        const isActive =
          studentId === classId ||
          (statusData &&
            statusData.trainingMode &&
            !["SessionEnded"].includes(statusData.trainingMode) &&
            statusData.countryCode);
        let progressPercent =
          statusData && statusData.percentComplete
            ? 100.0 * statusData.percentComplete
            : 0.0;

        let searchPass = true;
        if (studentSearch && studentData) {
          const searchLower = studentSearch.toLowerCase();
          if (
            (studentData.fullName || "").toLowerCase().includes(searchLower) ||
            studentId.toLowerCase().includes(searchLower)
          ) {
            searchPass = true;
          } else {
            searchPass = false;
          }
        }
        if ((showActiveOnly && !isActive) || !searchPass) {
          // Skip this one
        } else {
          resultList.push({
            id: studentId,
            email: studentId,
            isActive: isActive,
            facilitator: studentId === classId,
            fullName: studentData ? studentData.fullName : "Unknown",
            market:
              studentData && marketNamesById[studentData.market]
                ? marketNamesById[studentData.market]
                : "--",
            dealer:
              studentData && studentData.dealer ? studentData.dealer : "--",
            progress:
              statusData && statusData.percentComplete ? (
                <ThickLinearProgress
                  key={studentId}
                  variant="determinate"
                  value={progressPercent}
                  style={{ width: "100%", display: "inline-block" }}
                />
              ) : (
                "---"
              ),
            progressPercent: progressPercent,
            //currentClass: statusData && statusData.currentClass ? statusData.currentClass : "---",
            trainingMode:
              statusData && statusData.trainingMode
                ? statusData.trainingMode
                : "---",
            currentLesson:
              statusData && statusData.currentLesson
                ? statusData.currentLesson
                : "---",
            elapsedTime: elapsedTime,
            currentContent:
              statusData && statusData.currentContent
                ? statusData.currentContent
                : "---",
            connectedTime: connectedTime,
            countryName:
              statusData &&
              statusData.countryCode &&
              statusData.countryCode in countryNames
                ? countryNames[statusData.countryCode]
                : "--",
            regionName:
              statusData &&
              statusData.currentRegion &&
              regionNames &&
              statusData.currentRegion in regionNames
                ? regionNames[statusData.currentRegion]
                : statusData
                ? statusData.currentRegion || "--"
                : "--",
            contentThumbnail:
              statusData && statusData.contentThumbnail
                ? statusData.contentThumbnail
                : defaultContentThumbnail,
            launchURL:
              statusData && statusData.launchURL ? statusData.launchURL : "",
            userThumbnail:
              currentClass && currentClass.useWebcam
                ? statusData && statusData.userThumbnail
                  ? statusData.userThumbnail
                  : defaultUserThumbnail
                : defaultUserThumbnail
          });
        }
        return resultList;
      },
      []
    );
  }

  const [selectedStudentId, setSelectedStudentId] = React.useState("");

  const classes = useStyles();

  const selectedStudentData = studentList.find(
    entry => entry.email === selectedStudentId
  );

  //console.log("selectedStudentId: "+selectedStudentId);

  const handleStudentSelected = React.useCallback(newId => {
    // Toggle behavior
    setSelectedStudentId(currentId => {
      return currentId == newId ? "" : newId;
    });
  }, []);

  const handleClassSelected = React.useCallback(
    id => {
      if (id != classId) {
        // Unselect the student
        setSelectedStudentId("");
      }
      setClassId(id);
    },
    [classId]
  );

  const activeCount = studentList.filter(
    item => item.isActive && !item.facilitator
  ).length;
  return (
    <div
      className={classes.root}
      style={{ overflowY: "scroll", height: "85vh" }}
    >
      <Grid container style={{ width: "98%" }} spacing={2}>
        <Grid item xs={12}>
          <Card style={{ marginTop: "0", marginBottom: "0" }}>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <ClassIcon />
              </CardIcon>
              <span style={{ display: "flex", margin: "10px" }}>
                <h1
                  className={classes.cardIconTitle}
                  style={{ minWidth: "300px" }}
                >
                  {allClassesData && allClassesData.listClasses ? (
                    <ClassSelectMenu
                      className={classes.formControl}
                      currentSelectedId={classId}
                      allClasses={allClassesData.listClasses.items}
                      onSelected={handleClassSelected}
                    />
                  ) : null}
                </h1>

                {currentClass && currentClass.students ? (
                  <>
                    <span className={classes.currentClassDetails}>
                      Assigned
                      <br />
                      {currentClass && currentClass.students
                        ? currentClass.students.length
                        : "---"}
                    </span>

                    <span className={classes.currentClassDetails}>
                      Active
                      <br />
                      {activeCount}
                    </span>
                    {/*
                <span className={classes.currentClassDetails}>
                  Start Date<br/>
                  {moment.unix(currentClass.startDate).format("DD MMM YYYY")}
                </span>
                <span className={classes.currentClassDetails}>
                  End Date<br/>
                  {moment.unix(currentClass.endDate).format("DD MMM YYYY")}
                </span>
                <span className={classes.currentClassDetails}>
                  Start<br/>
                  {moment.unix(currentClass.dailyStartTime + moment().utc().startOf('day').unix()).format("HH:mm")}
                </span>
                <span className={classes.currentClassDetails}>
                  End Time<br/>
                  {moment.unix(currentClass.dailyEndTime + moment().utc().startOf('day').unix()).format("HH:mm")}
                </span>
                */}
                  </>
                ) : null}

                <span
                  className={classes.cardIconTitle}
                  style={{ textAlign: "center", flexGrow: 1, float: "right" }}
                >
                  {currentClass
                    ? currentClass.content.map(contentId => {
                        return (
                          <Chip
                            style={{ margin: "2px", maxWidth: "10em" }}
                            key={"content_chip" + contentId}
                            label={contentNameById[contentId] || "---"}
                          />
                        );
                      })
                    : null}{" "}
                </span>
              </span>
            </CardHeader>
          </Card>
        </Grid>
        <br />
        <Grid style={classId ? {} : { display: "none" }} item xs={12}>
          <Paper className={classes.paper}>
            <span style={{ display: "flex" }}>
              <Typography color="textSecondary" variant="h5" component="h2">
                Students
              </Typography>

              <span style={{ flexGrow: "1" }}>
                <FormGroup row style={{ float: "right" }}>
                  <TextField
                    type="search"
                    label={`🔍`}
                    style={{
                      marginLeft: "50px",
                      transform: "translateY(-15px)",
                      width: "300px",
                      marginRight: "20px"
                    }}
                    onChange={event => {
                      setStudentSearch(event.target.value);
                    }}
                  />
                  <FormControlLabel
                    style={{ marginRight: "20px" }}
                    control={
                      <Switch
                        color="primary"
                        checked={showActiveOnly}
                        onChange={event => {
                          setShowActiveOnly(event.target.checked);
                        }}
                        name="activeOnly"
                      />
                    }
                    label="Active Only"
                  />
                  {!compactStudentView ? (
                    <FormControlLabel
                      style={{ marginRight: "20px" }}
                      control={
                        <Switch
                          color="primary"
                          checked={doScroll}
                          onChange={event => {
                            setDoScroll(event.target.checked);
                          }}
                          name="doScroll"
                        />
                      }
                      label="Single Row"
                    />
                  ) : null}
                  <FormControlLabel
                    style={{ marginRight: "20px" }}
                    control={
                      <Switch
                        color="primary"
                        checked={compactStudentView}
                        onChange={event => {
                          setCompactStudentView(event.target.checked);
                        }}
                        name="compact"
                      />
                    }
                    label="Compact"
                  />
                </FormGroup>
              </span>
            </span>
            <LiveClassList
              selectedId={selectedStudentData ? selectedStudentData.email : ""}
              showWebcam={currentClass && currentClass.useWebcam}
              compactMode={compactStudentView}
              classId={classId}
              showActiveOnly={false}
              studentList={studentList}
              className={className}
              onSelected={handleStudentSelected}
              doScroll={doScroll}
            />
          </Paper>
        </Grid>
        <Grid style={classId ? {} : { display: "none" }} item xs={12}>
          <Paper className={classes.paper}>
            <Typography color="textSecondary" variant="h5" component="h2">
              Details
            </Typography>
            <LiveStudentDashboardDetail
              item={selectedStudentData}
              classRegion={classRegionId}
              showChat={currentClass && !currentClass.hideChat}
            />
          </Paper>
        </Grid>
      </Grid>
      {/*<InstructorChat studentName={currentStudentData.email}/>*/}
    </div>
  );
};
export default FacilitatorDashboard;
