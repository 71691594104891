import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import distiAuth from "disti-auth.js";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  chatSection: {
    width: "100%"
    //    height: '550px'
  },
  headBG: {
    backgroundColor: "#e0e0e0"
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0"
  },
  messageArea: {
    height: "400px",
    overflowY: "auto"
  }
});

const Chat = ({ disabled, currentData, allCount, onSend }) => {
  const classes = useStyles();

  const [currentText, setCurrentText] = React.useState("");
  const endMessagesRef = React.useRef(null);

  const username = distiAuth.getUsername();

  React.useEffect(() => {
    if (endMessagesRef.current) {
      endMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [allCount]);

  const handleSend = () => {
    if (currentText) {
      if (onSend(currentText)) {
        setCurrentText("");
      }
    }
  };
  const align = item => {
    return item.who == username ? "right" : "left";
  };
  const color = item => {
    return item.whoType == "INSTRUCTOR" ? "red" : "blue";
  };
  //                                    <ListItemText style={{display:"flex", flexDirection: "column"}}
  //                                        primary={<span style={{background: color(item), borderRadius:"10px"}}>{item.what}</span>}

  return (
    <div>
      <Grid container component={Paper} className={classes.chatSection}>
        <Grid item xs={12}>
          <List className={classes.messageArea}>
            {currentData.map((item, index, fullArray) => {
              return (
                <ListItem key={index}>
                  <Grid
                    container
                    ref={index == fullArray.length - 1 ? endMessagesRef : null}
                  >
                    <Grid item xs={12}>
                      <ListItemText
                        align={align(item)}
                        primary={item.what}
                        secondary={
                          item.who != username ? (
                            <span style={{ color: color(item) }}>
                              {item.whoFull || item.who}
                            </span>
                          ) : (
                            ""
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ListItemText
                        variant="body2"
                        style={{ color: color(item) }}
                        align={align(item)}
                        secondary={new Date(item.when).toLocaleTimeString()}
                      ></ListItemText>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </List>
          <Divider />
          <Grid container style={{ padding: "20px" }}>
            <Grid item xs={10}>
              <TextField
                value={currentText}
                onChange={event => {
                  setCurrentText(event.target.value);
                }}
                onKeyPress={ev => {
                  if (ev.key === "Enter") {
                    handleSend();
                    ev.preventDefault();
                  }
                }}
                label="Message"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Fab
                disabled={disabled}
                color="primary"
                aria-label="add"
                onClick={handleSend}
              >
                <SendIcon />
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Chat;
