import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import UpdateIcon from "@material-ui/icons/Update";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import EmailIcon from "@material-ui/icons/Email";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import InputAdornment from "@material-ui/core/InputAdornment";
import OrganizationChooser from "components/StudentTable/OrganizationChooser.js";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import ListItem from "@material-ui/core/ListItem";
import ListIcon from "@material-ui/icons/List";
import { Grid } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import pLimit from 'p-limit';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import ReactTable from "react-table-6";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import Queries from "GraphQL/InstructorAccess.js";

import distiAuth from "disti-auth.js";
import util from "util.js";
import Snackbar from 'components/Snackbar/Snackbar.js';
import settings from '../../aws-exports.json';
import SubscriptionHelpers from "../../GraphQL/SubscriptionHelpers.js";
//"../GraphQL/SubscriptionHelpers.js";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    entryLine: {
        width: "100%",
        marginTop: "15px",
        marginBottom: "15px"
    }
});

export default function BulkPersonIdEdit({ onProcessData, onCancel }) {

    const [showImport, setShowImport] = React.useState(false);
    const [rawImportData, setRawImportData] = React.useState("");
    const [warningsAcknowledged, setWarningsAcknowledged] = React.useState(
        new Set()
      );
    const addRemoveWarningsAcknowledged = (add, email) => {
        let copy = new Set(warningsAcknowledged);
        if (add) {
            copy.add(email);
        } else {
            copy.delete(email);
        }
        setWarningsAcknowledged(copy);
    };
    
    let {
        loading: queryLoading,
        data: queryData
    } = SubscriptionHelpers.useSubscribedStudentsQuery();

//    const { loading: queryLoading, data: queryData } = useQuery(Queries.STUDENTS.ALL);

    const [bulkEditData,setBulkEditData] = React.useState({
        items: [],
        status: { errors: false, warningsCount: 0 }
    })
    const [checkingData, setCheckingData] = React.useState(false);
    React.useEffect(()=> {
        setCheckingData(true);
        const doIt = ()=>{           
            if (!queryLoading && queryData && queryData.listStudents) {
                let allStudentsRaw = queryData.listStudents.items;
                let calculated = [];
                let status = {
                    errors: false, warningsCount: 0
                };

                // Parse the raw import data.  Find an email and a personId.
                let pairs = rawImportData.split('\n');
                const importData = [];
                for (const p of pairs) {
                    const pair = p.trim(); // Ignoring leading or trailing whitespace
                    if (!pair) continue; // Skip blank lines

                    // Split on whitespace
                    // Note that this will collapse multiple whitespace
                    let data = pair.split(/\s+/);

                    const email = data[0].toLowerCase();
                    
                    // Put back the whitespace, but only one space
                    const personId = data.length > 1 ? data.slice(1).join(' ') : '';
                    importData.push({email, personId});
                }
                let rowNum = 0;
                const allStudentEmailsLower = allStudentsRaw.map(item=>item.id.toLowerCase());
                for (const {email, personId} of importData)
                {
                    rowNum++;
                    const errorEntries = [];
                    const warningEntries= [];
                    const existingEntryById = allStudentEmailsLower.find(item => item === email);

                    // Check for duplicates in the importData... an error
                    if (1 < importData.filter(entry=>entry.email.toLowerCase() === email.toLowerCase()).length)
                    {
                        status.errors = true;
                        errorEntries.push((
                            <>
                        {`Duplicate email addresses in data`}
                        </>),
                        (<br/>)
                        )
                        
                    }

                    // Check for changing of personId... this is only a warning
                    if (existingEntryById && existingEntryById.personId && existingEntryById.personId != personId)
                    {
                        status.warningsCount += 1;
                        warningEntries.push((
                            <>
                            Changing {settings.personIdFieldName} from: {existingEntryById.personId}
                            </>
                        ));

                    }
                    const lowerPersonId = personId.toLowerCase()
                    const mismatchedCase = allStudentsRaw.find(item=>{
                        if (!item.personId) return false;
                        if (item.personId === personId) return false;
                        if (item.personId.toLowerCase() === lowerPersonId) return true;
                    })
                    if (mismatchedCase)
                    {
                        status.errors = true;
                        errorEntries.push((
                            <>
                        {`Case of ${settings.personIdFieldName} doesn't match existing: ${mismatchedCase.personId}`}
                        </>),
                        (<br/>)
                        )
                    }

                    if (!existingEntryById) // This email is not in the database
                    {
                        status.errors = true;
                        errorEntries.push((
                            <>
                            Email is not in the system.
                            </>),(<br/>))
                    }

                    if (personId === "") // A personId was not submitted for this entry
                    {
                        status.errors = true;
                        errorEntries.push((
                            <>
                            &quot;{settings.personIdFieldName}&quot; must be provided.
                            </>)
                            ,(<br />)
                        );

                    }

                    let newEntry = {
                        email,
                        personId,
                        errors: errorEntries,
                        warnings: warningEntries,
                        errorWarningSortData: (errorEntries.length ? -2e12 : 0) +
                                            (warningEntries.length ? -2e8 : 0) +
                                            rowNum
                    }

                    calculated.push(newEntry);
                }

                setBulkEditData({
                    items: calculated,
                    status: status
                });
            }
            setCheckingData(false);
        }
        doIt();
    },[rawImportData, queryLoading, queryData?.length]);
    
    const handleCancel = () => {
        onCancel()
    };

    const handleApply = async () => {
        onProcessData([...bulkEditData.items])
    };

    const okayToEditAll = () => {
        return (
            bulkEditData &&
            !bulkEditData.status.errors &&
            bulkEditData.status.warningsCount <= warningsAcknowledged.size
        );
    };

    const classes = useStyles();

    const [tempRawData, setTempRawData] = React.useState("");

    return (
        <>
            <Dialog
                open={Boolean(showImport)}
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby="form-dialog-title"
            >
                    <>
                        <DialogTitle id="form-dialog-title">
                            <CloseIcon
                                style={{ position: "absolute", right: "25px", zIndex: "1" }}
                                fontSize="large"
                                onClick={handleCancel}
                            />
                        </DialogTitle>
                        <DialogContent>
                            Input one entry per line.
                            <br />
                            ex:
                            <br />
                            <i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;example@example.com&nbsp;&nbsp;&nbsp;123-456</i>
                            <br />
                            <br />
                            <TextField
                                fullWidth
                                error={!tempRawData}
                                id="standard-multiline-flexible"
                                label="Import Data"
                                multiline
                                minRows={10}
                                maxRows={20}                                
                                onChange={e => { setTempRawData( e.target.value )}}
                                variant="outlined"
                            />
                            <br />
                            <br />
                            <Button
                                color="primary"
                                disabled={!tempRawData}
                                style={{
                                    float: "left",
                                    marginLeft: "100px",
                                    marginTop: "20px",
                                    marginBottom: "20px"
                                }}
                                onClick={() => {
                                    setRawImportData(tempRawData) 
                                    setShowImport(false);
                                }}>
                                Validate
                            </Button>
                            <Button
                                color="primary"
                                style={{
                                    float: "right",
                                    marginLeft: "100px",
                                    marginTop: "20px",
                                    marginBottom: "20px"
                                }}
                                onClick={() => {
                                    handleCancel();
                                }}>
                                Cancel
                            </Button>
                        </DialogContent>
                    </>
            </Dialog>

            <Grid container>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <PersonIcon />
                            </CardIcon>
                            <h1 className={classes.cardIconTitle}>
                                Bulk Set {settings.personIdFieldName}s
                                <span style={{ float: "right" }}>
                                    <Button
                                        round
                                        color="primary"
                                        disabled={false}
                                        onClick={() => {
                                            setShowImport(true);
                                        }}>
                                        Import New Data
                                    </Button>
                                </span>
                            </h1>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={bulkEditData.items}
                                style={{
                                    height: "calc( 100vh - 450px)"
                                }}
                                columns={[
                                    {
                                        Header: "",
                                        accessor: "errorWarningSortData",
                                        width: 100,
                                        Cell: row => [
                                            row.original.errors.length ? (
                                                <Tooltip
                                                    key={"error_" + row.original.row}
                                                    title={row.original.errors}
                                                >
                                                    <ErrorIcon
                                                        style={{ float: "left", color: "red" }}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                ""
                                            ),
                                            row.original.warnings.length? (
                                                <span
                                                    key={"warning_" + row.original.row}
                                                    style={{ float: "right" }}
                                                >
                                                    <Tooltip title={row.original.warnings}>
                                                        <WarningIcon
                                                            style={{ color: "orange" }}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={"Acknowledge Warning"}
                                                        style={{ float: "left" }}
                                                    >
                                                        <Checkbox
                                                        style={{ float: "right", padding: "0px" }}
                                                        color="primary"
                                                        checked={warningsAcknowledged.has(
                                                            row.original.email
                                                        )}
                                                        onChange={e => {
                                                            addRemoveWarningsAcknowledged(
                                                            e.target.checked,
                                                            row.original.email
                                                            );
                                                        }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            ) : (
                                                ""
                                            ),
                                            row.original.marketError ? (
                                                <Tooltip
                                                    key={"market_error_" + row.original.row}
                                                    title={row.original.marketError}
                                                >
                                                    <ErrorIcon
                                                        style={{ float: "left", color: "red" }}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                ""
                                            )
                                        ]
                                    },
                                    {
                                        Header: "Email",
                                        accessor: "email",
                                        getProps: (state, rowInfo, column) => {
                                            if (!rowInfo) {
                                                return {};
                                            }
                                            return {
                                                style: rowInfo.original.errors.length
                                                    ? { textShadow: "0px 0px 20px red" }
                                                    : {}
                                            };
                                        },
                                        Cell: row => <Typography>{row.value}</Typography>
                                    },
                                    {
                                        Header: settings.personIdFieldName,
                                        accessor: "personId",
                                        Cell: row => <Typography>{row.value}</Typography>
                                    }
                                ]}
                                defaultSorted={[
                                    {
                                      id: "errorWarningSortData",
                                      desc: false
                                    }
                                  ]}
                                />
                                {checkingData?(
                        <CircularProgress
                              disableShrink
                              style={{
                                position: "absolute",
                                top: "250px",
                                // Center it left/right:
                                left: "0px",
                                right: "0px",
                                marginLeft: "auto",
                                marginRight: "auto"
                              }}
                            />
                            ):null}
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>
            <br />
            <Button
                color="primary"
                style={{
                    float: "left",
                    marginLeft: "100px",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}
                disabled={ !okayToEditAll() }
                onClick={async () => {
                    handleApply();
                }}
            >
                Apply
            </Button>
            <Button
                style={{
                    float: "right",
                    marginRight: "100px",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}
                onClick={() => { 
                    onCancel(); 
                }}
            >
                Cancel
            </Button>
        </>
    );
}