import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles(theme => ({
  wideForm: {
    //margin: theme.spacing(1),
    width: "100%",
    minWidth: 120
  },
  narrowForm: {
    //margin: theme.spacing(1),
    //width: "100%",
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default ({
  availableOrganizations,
  currentOrganization,
  wide,
  onOrganizationChange,
  required,
  error,
  disabled
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sortedOrganizations = availableOrganizations.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return (
    <FormControl
      disabled={disabled}
      error={required && !currentOrganization}
      className={wide ? classes.wideForm : classes.narrowForm}
    >
      <InputLabel shrink id="demo-simple-select-placeholder-label-label">
        Market {required ? "*" : ""}
      </InputLabel>
      <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        value={currentOrganization}
        onChange={event => {
          onOrganizationChange(event.target.value);
        }}
        displayEmpty
        className={classes.selectEmpty}
        error={error}
      >
        {sortedOrganizations.map(entry => {
          return (
            <MenuItem key={entry.id} value={entry.id}>
              {entry.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
